import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";
import { useDispatch, useSelector } from "react-redux";
import {
  electricOperators,
  electricValidation,
  electricityBeneficiary,
  electricitySubscription,
} from "../../data/local/reducers/miscellaneous.reducer";
import Loading from "../SelectValue/Loading";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import { showErrorToast, showSuccessToast } from "../../utils/api-utils";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";

function ShagoElectricityForm() {
  const dispatch = useDispatch();
  const [value, setValue] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const handleClose = () => setValue(false);
  const [electricityList, setElectricity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const users = useSelector((state) => state.misc);
  const [electricityValues, setElectricityValues] = useState("");
  const [operatorId, setOperatorId] = useState("");
  const [serviceCode, setServiceCode] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [beneficiary, setBeneficiary] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [savedbeneficiary, setSavedBeneficiary] = useState(false);

  const billerOptions = [
    { key: "Select an option", value: "" },
    { key: "prepaid", value: "1" },
    { key: "postpaid", value: "2" },
  ];

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
    if (showDropdown) {
      setSelectedBeneficiary(""); // Reset selected beneficiary when hiding the dropdown
    }
    if (!showDropdown) {
      electricityBeneficiaries();
    }
  };

  const electricityBeneficiaries = async () => {
    const { payload } = await dispatch(
      electricityBeneficiary({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    const { result } = payload;
    setBeneficiary(result);
  };

  const electricityBenefit = beneficiary ? [...beneficiary] : [];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const provider = "DML";
      const { payload } = await dispatch(electricOperators(provider));
      setElectricity(payload.result);
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const verifyElectricityValue = async (
    biller,
    account_type_id,
    meterNumber
  ) => {
    const operatorId = biller.split("|")[0];
    const serviceCode = biller.split("|")[1];
    setOperatorId(operatorId);
    setServiceCode(serviceCode);

    if (meterNumber?.length === 11) {
      const { payload } = await dispatch(
        electricValidation({
          card_identity: meterNumber,
          service_code: serviceCode,
          account_type_id: account_type_id,
          operator_id: operatorId,
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          provider: "DML",
          user_type: data.user_type,
          channel: "web",
          token: token,
        })
      );

      if (payload) {
        const { results } = payload;
        setElectricityValues(results);
      }
    }
  };

  const electricityLists = electricityList
    ? [{ code: "", name: "Select an option" }, ...electricityList]
    : [];

  const accountTypeOptions = [
    { key: "Select an option", value: "" },
    { key: "Prepaid", value: "1" },
    { key: "Postpaid", value: "2" },
  ];

  const initialValues = {
    biller: "",
    account_type_id: "",
    card_identity: "",
    payableAmount: "",
    buyer_email: "",
    buyer_phonenumber: "",
    amount: "",
  };

  const validationSchema = Yup.object({
    biller: Yup.string().required("Required"),
    account_type_id: Yup.string().required("Required"),
    card_identity: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    resetForm();
  };
  const onSubmit = async (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    const formData = {
      ...formValues,
      service_code: serviceCode,
      operator_id: operatorId,
      provider_ref: "",
      customer_name: electricityValues?.name ?? "",
      customer_address: electricityValues?.address ?? "",
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
      operation_summary: "electricity",
      provider: "DML",
      phonenumber: data.phonenumber,
    };

    handleClose();
    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(electricitySubscription(formData))
        : "";
    dispatch(
      fetchUserBalance({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    dispatch(
      fetchTransactionList({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );

    if (payload.status_code === "0") {
      resetForm();
      showSuccessToast(payload.message);
    }
  };
  return (
    <div>
      <Loading open={users.loading} />
      <SecurityAnswerModal
        open={value}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />
      <div className="flex justify-end mr-4">
        <button
          type="button"
          className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 font-semibold px-3 py-2 text-sm rounded shadow"
          onClick={handleDropdownToggle}
        >
          Show Beneficiaries
        </button>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange }) => (
          <Form>
            <Loading open={users.loading} />
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              {showDropdown && (
                <FormikControl
                  control="selectBeneficiary"
                  options={electricityBenefit}
                  label="Select wallet beneficiary:"
                  name="bank_code"
                  value={values.walletBeneficiaries}
                  onChange={(event) => {
                    setSelectedBeneficiary(event.target.value); // Update selected beneficiary value
                  }}
                  error={touched.bank_code && errors.bank_code}
                />
              )}
              <FormikControl
                control="selectElectricity"
                options={electricityLists}
                label="Select electricity biller:"
                name="biller"
                value={values.biller}
                onChange={(event) => {
                  handleChange(event);
                  verifyElectricityValue(
                    event.target.value,
                    values.account_type_id,
                    values.meterNumber
                  );
                }}
                error={touched.biller && errors.biller}
              />

              <FormikControl
                control="select"
                options={accountTypeOptions}
                label="Account type:"
                name="account_type_id"
                value={values.account_type_id}
                onChange={(event) => {
                  handleChange(event);
                  verifyElectricityValue(
                    values.biller,
                    event.target.value,
                    values.meterNumber
                  );
                }}
                error={touched.account_type_id && errors.account_type_id}
              />

              <FormikControl
                control="input"
                type="integer"
                label="Meter Number:"
                name="card_identity"
                value={values.card_identity}
                onChange={(event) => {
                  handleChange(event);
                  verifyElectricityValue(
                    values.biller,
                    values.account_type_id,
                    event.target.value
                  );
                }}
                error={touched.card_identity && errors.card_identity}
              />

              <FormikControl
                control="input"
                type="text"
                label="Customer name:"
                name="customer_name"
                value={electricityValues?.name ?? ""}
                disabled
              />

              <FormikControl
                control="input"
                type="text"
                label="Customer address:"
                name="customer_address"
                value={electricityValues?.address ?? ""}
                disabled
              />

              <FormikControl
                control="input"
                type="integer"
                label="Minimum payable amount:"
                name="payableAmount"
                value={electricityValues?.minAmount ?? ""}
                disabled
              />

              <FormikControl
                control="input"
                type="text"
                label="Buyer's Phone Number:"
                name="buyer_phonenumber"
                value={values.buyer_phonenumber}
                error={touched.buyer_phonenumber && errors.buyer_phonenumber}
              />

              <FormikControl
                control="input"
                type="text"
                label="Buyer's Email:"
                name="buyer_email"
                value={values.buyer_email}
                error={touched.buyer_email && errors.buyer_email}
              />

              <FormikControl
                control="input"
                type="integer"
                label="Amount:"
                name="amount"
                value={values.amount}
                error={touched.amount && errors.amount}
              />
            </div>
            {!showDropdown && (
              <div className="px-4 text-sm items-center text-slate-600 mb-10 flex gap-3">
                <input
                  type="checkbox"
                  name="Save beneficiary"
                  id="save_beneficiary"
                  checked={values.send_message}
                  onChange={() => setSavedBeneficiary(!savedbeneficiary)}
                />
                <span>Save as beneficiary</span>
              </div>
            )}

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ShagoElectricityForm;

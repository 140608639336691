import React from "react";
import Call from "../../images/Airtime and data.svg";
import Send from "../../images/money transter.svg";
import Play from "../../images/TvSub.svg";
import Power from "../../images/electricity.svg";
import Home from "../../images/private estates.svg";
import Buy from "../../images/bulk operations.svg";
import receipt from "../../images/receipt.svg";
import Exams from "../../images/Exams.svg";
import PageCard from "./PageCard";

function Services() {
  return (
    <div className="rounded-lg border text-sm">
      <div className="p-4 border-b">
        <p className="font-bold">Services</p>
      </div>
      <div className="p-4 grid grid-cols-3 gap-5 md:grid-cols-4 lg:grid-cols-5 ">
        <PageCard img={Call} name="Airtime and Data" link="/rechargeaccount" />
        <PageCard img={Send} name="Money transfer" link="/transfer" />
        <PageCard img={Play} name="Tv Subscription" link="/subscription" />
        <PageCard img={Power} name="Electricity" link="/electricity" />
        <PageCard
              img={receipt}
              name='Remita Payment'
              link="/remita" 
            />
        <PageCard img={Home} name="Private Estates" link="/privateestate" />
        <PageCard img={Exams} name="Exams" link="/exam"/>
        <PageCard img={receipt} name="Invoice Option" link="/invoiceOption" />
        <PageCard img={Buy} name="Bulk Operations" link="/bulkOperations" />
        <PageCard
              img={receipt}
              name='Transaction Statement'
              link="/printReceipts"
            />
        
        
      </div>
    </div>
  );
}

export default Services;

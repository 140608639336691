import { React } from "react";
import Back from "../../Components/Back";
import StoreAd from "../../Components/storeAdvert";
import pin from "../../images/pin.svg";
import securityAnswer from "../../images/security-answer.svg";
import { Link } from "react-router-dom";

function SecurityDetails() {
  return (
    <div>
      <div className="flex items-center gap-4 pb-4 text-sm font-bold border-b">
        <Back />
        <span>Reset security details</span>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-8">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 gap-5">
            <div className="rounded-lg border text-sm">
              <div className="p-4 border-b">
                <p className="font-bold">Change your pin or security answer</p>
              </div>
              <div className="p-4 flex gap-10 items-start ">
                <div className="grid justify-items-center cursor-pointer">
                  <Link to="/changeSecurity">
                    <img src={pin} alt="img" />
                    <p className="pt-3 text-xs text-center">Change Pin</p>
                  </Link>
                </div>

                <div className="grid justify-items-center">
                  <Link to="/resetPin">
                    <img src={securityAnswer} alt="img" />
                  </Link>
                  <p className="pt-3 text-xs text-center">
                    Change Security Answer
                  </p>
                </div>
                <div className="grid justify-items-center">
                  <Link to="/transactionPin">
                    <img src={securityAnswer} alt="img" />
                  </Link>
                  <p className="pt-3 text-xs text-center">
                    Change Transaction Pin
                  </p>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5">
          <StoreAd />
        </div>
      </div>
    </div>
  );
}

export default SecurityDetails;

import React from "react";
import RecentTransactions from "../../Components/recentTransactions";
import StoreAd from "../../Components/storeAdvert";
import Back from "../../Components/Back";
import History from "../../Components/Invoicing/history";
import DownloadTemplate from "../../Components/DownloadTemplate";

function HistoryScreen() {

  return (
    <div>
      <div className="flex items-center text-sm font-bold gap-4 border-b pb-4">
        <Back />
        <span>Invoice History</span>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 gap-5">
            <div className="rounded-lg border text-sm">
              <DownloadTemplate />
              <div className="py-4 grid grid-cols-1">
                <History />
              </div>
            </div>
          </div>
        </div>
        <div className="grid gap-5">
          <StoreAd />
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default HistoryScreen;

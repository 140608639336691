import Back from "../../Components/Back";
import StoreAd from "../../Components/storeAdvert";
import { Link } from "react-router-dom";

const ProfileManagement = () => {
  return (
    <div>
      <div className="flex items-center text-sm font-bold gap-4 border-b pb-4">
        <Back />
        <span>Profile management</span>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5">
        <div className="lg:col-span-2">
        <div className="w-full mb-5 text-sm font-semibold">
          <Link to="/AccountDetails" className="flex justify-between p-4 hover:bg-[#127EC810] hover:text-[#127EC8] hover:rounded-md hover:px-6 transition duration-300">
            <div className="self-center">Account details</div>
            <div className="self-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12.1717 12.0005L9.34326 9.17203L10.7575 7.75781L15.0001 12.0005L10.7575 16.2431L9.34326 14.8289L12.1717 12.0005Z"></path></svg>
            </div>
          </Link>

          <Link to="/SecurityDetails" className="flex justify-between p-4 hover:bg-[#127EC810] hover:text-[#127EC8] hover:rounded-md hover:px-6 transition duration-300">
            <div className="self-center">Reset security details</div>
            <div className="self-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12.1717 12.0005L9.34326 9.17203L10.7575 7.75781L15.0001 12.0005L10.7575 16.2431L9.34326 14.8289L12.1717 12.0005Z"></path></svg>
            </div>
          </Link>

          <Link to="/updateID" className="flex justify-between p-4 hover:bg-[#127EC810] hover:text-[#127EC8] hover:rounded-md hover:px-6 transition duration-300">
            <div className="self-center">Update valid ID</div>
            <div className="self-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12.1717 12.0005L9.34326 9.17203L10.7575 7.75781L15.0001 12.0005L10.7575 16.2431L9.34326 14.8289L12.1717 12.0005Z"></path></svg>
            </div>
          </Link>
          
          <Link to="/softPOS" className="flex justify-between p-4 hover:bg-[#127EC810] hover:text-[#127EC8] hover:rounded-md hover:px-6 transition duration-300">
            <div className="self-center">Soft POS</div>
            <div className="self-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12.1717 12.0005L9.34326 9.17203L10.7575 7.75781L15.0001 12.0005L10.7575 16.2431L9.34326 14.8289L12.1717 12.0005Z"></path></svg>
            </div>
          </Link>

          {/* <Link to="/" className="flex justify-between p-4 hover:bg-[#127EC810] hover:text-[#127EC8] hover:rounded-md hover:px-6 transition duration-300">
            <div className="self-center">Notification settings</div>
            <div className="self-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M12.1717 12.0005L9.34326 9.17203L10.7575 7.75781L15.0001 12.0005L10.7575 16.2431L9.34326 14.8289L12.1717 12.0005Z"></path></svg>
            </div>
          </Link> */}
          
          </div>
        </div>
        <div className="grid gap-5">
          <StoreAd />
        </div>
      </div>
    </div>
  );
};

export default ProfileManagement;

import React from 'react'
import Types from '../../Components/types';

function SelectTypes() {
  return (
    <Types pageName='DSTV' navigateDiamond={'/selectService'} navigatePlatinum={'/selectPlatinum'}  />
  )
}
export default SelectTypes;




import TopSec from "../../Components/Home/TopSec"
import Features from "../../Components/Home/Features"
import Advert from "../../Components/Home/Advert"
import GetStarted from "../../Components/Home/getStarted"
import Footer from "../../Components/Home/Footer"
import Support from "../../Components/Home/Support"
import Services from "../../Components/Home/Services"

function Home() {
   
  return (
    <div className="home-bg bg-slate-600">
      <TopSec/>
      <Features />
      <Services />
      <div className="grid grid-cols-1 py-10 px-4 md:px-16 items-center gap-5">
        <Support />
        <Advert />      
      </div>
      <GetStarted />
      <Footer />
    </div>
  )
}

export default Home
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import {
  bankTransfer,
  jambType,
  jambVend,
} from "../../data/local/reducers/miscellaneous.reducer";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import { showSuccessToast } from "../../utils/api-utils";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { saveAs } from "file-saver";
import BulkTransferList from "./bank_list";

function BulkTransferForm() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const phoneNumber = data.phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);
  const [isLoading, setIsLoading] = useState(false);
  const [showList, setShowList] = useState(false);
  const [exams, setExams] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { payload } = await dispatch(jambType());
      setExams(payload["result"]);

      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const initialValues = {
    password: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string().required("Required"),
  });

  async function generatePDF(bulkUploadRecords) {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 12;
    const lineHeight = fontSize * 1.2;
    const margin = 50;

    let y = page.getHeight() - margin;

    for (let i = 0; i < bulkUploadRecords.length; i++) {
      const record = bulkUploadRecords[i];
      const lines = record.split("\n"); // Split the record into lines

      for (let j = 0; j < lines.length; j++) {
        page.drawText(lines[j], {
          x: margin,
          y: y - j * lineHeight,
          size: fontSize,
          font,
          lineHeight,
        });
      }

      y -= lines.length * lineHeight;
    }

    const pdfBytes = await pdfDoc.save();

    // Return the PDF file as bytes
    return pdfBytes;
  }

  const processRecords = (index, payload) => {
    if (payload) {
      const formattedRecord = `${index + 1}. Message: ${
        payload.message || ""
      }\n Status: ${payload.status_message || ""
      }\n Recipient account number: ${
        payload.recipient || ""
      }\n Recipient account name: ${payload.recipient_name || ""
      }\n Amount: ${payload.amount || ""
      }\n Reference: ${payload.reference || ""
    }\n`;
      return formattedRecord;
    } else {
      console.error("Payload or payload.result is undefined:", payload);
      return ""; // Return an empty string or handle the error accordingly
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    const file = values.uploadfile;
    const reader = new FileReader();
    // Define a promise for reading the file
    const fileReadPromise = new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
    const bulkUploadRecords = [];
    // Start reading the file as text
    reader.readAsText(file);
    try {
      setIsLoading(true);
      const fileContents = await fileReadPromise;
      const records = fileContents.split("\n");
      // Send each record to the API
      for (let i = 0; i < records.length; i++) {
        const line = records[i];
        const [account_number, amount, bank_code, recipient_name] = line
          .split(",")
          .map((item) => item.trim());

        if (line.length > 0) {
          const lineData = {
            bank_code: bank_code,
            security_answer: values.password,
            account_number: account_number,
            account_name: recipient_name,
            transaction_id: generateTransactionId(),
            channel: "web",
            user_type: data.user_type,
            token: token,
            operation_summary: "Bank transfer",
            sender_phonenumber: data.phonenumber,
            phonenumber: data.phonenumber,
            amount: amount,
          };
          const { payload } = await dispatch(bankTransfer(lineData));
          const formattedRecord = processRecords(i, payload);
          bulkUploadRecords.push(formattedRecord);
        }
      }
      dispatch(
        fetchUserBalance({
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
      dispatch(
        fetchTransactionList({
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
      resetForm();
      setIsLoading(false);
      const pdfBytes = await generatePDF(bulkUploadRecords);
      //Save the PDF file using FileSaver.js
      saveAs(
        new Blob([pdfBytes], { type: "application/pdf" }),
        "bulk_upload_records.pdf"
      );
      //Show the toast message with the bulk upload records
      showSuccessToast("Bulk Operation Completed");
    } catch (error) {
      console.error("Error reading file:", error);
      showSuccessToast("An error occurred during bulk upload.");
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Loading open={users.loading} />
      <div className="flex justify-end mr-4">
        <button
          type="button"
          className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 font-semibold px-3 py-2 text-sm rounded shadow"
          onClick={() => setShowList(!showList)}
        >
          {showList ? "Show Bulk Transfer Form" : "Show Bank Code"}
        </button>
      </div>

      {showList ? (
        <BulkTransferList />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleChange, touched, setFieldValue }) => (
            <Form>
              <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2">
                <FormikControl
                  control="input"
                  type="text"
                  label="Wallet number:"
                  name="walletaccount"
                  value={phoneNumber}
                  disabled
                />

                <div className="flex items-center">
                  <input
                    type="file"
                    id="uploadfile"
                    name="uploadfile"
                    placeholder="hello"
                    onChange={(event) => {
                      setFieldValue("uploadfile", event.currentTarget.files[0]);
                    }}
                    className="border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 file:py-4 file:px-4 file:bg-[#127EC8] file:text-white file:text-xs file:border-0"
                  />
                </div>

                <FormikControl
                  control="input"
                  type="password"
                  label="Security Answer:"
                  name="password"
                />
              </div>

              <div className="grid grid-cols-1 px-4 md:grid-cols-2">
                <Button name={"Continue"} type="submit" />
              </div>
              {isLoading && <Loading open={isLoading} />}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

export default BulkTransferForm;

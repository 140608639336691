import React from "react";
import { Link } from "react-router-dom";

function GetStarted() {
  return (
<div className="flex justify-center text-center px-4 md:px-16 gap-10 py-10 bg-black mt-10">
  <div className="getStarted rounded-lg p-5 md:p-10 text-white">
    <span className="bg-[#ffffff20] px-4 py-2 rounded-3xl">🏃🏾💨</span>
    <p className="text-xl font-bold mt-4 mb-2">Ready to get started?</p>
    <p>
      Create your zippyworld account in 2 minutes. Provide your BVN and
      we’ll handle the rest.
    </p>
    
    <div className="flex justify-center text-center gap-5 items-center mt-5">
      <div className="col-span-3 flex justify-center"> {/* Added justify-center here */}
        <Link to="/register">
          <button className="bg-white text-black px-4 py-6 md:py-4 rounded text-center text-sm w-full">
            Register with BVN
          </button>
        </Link>
      </div>
      
      {/* <div className="col-span-3 text-sm flex gap-3">
        <Link to="/registerNoBvn">
          <button className="bg-white/10 border text-white px-4 py-6 md:py-4 rounded text-center text-sm w-full">
            I don't have a BVN
          </button>
        </Link>
      </div> */}
    </div>
    <div className="flex items-center justify-center mt-10">
    <ul className="flex justify-center text-center items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
    <li className="mb-2 sm:mb-0">
            <Link to="/" className="hover:underline me-4 md:me-6">Home</Link>
        </li>
        <li className="mb-2 sm:mb-0">
            <Link to="/privacy" className="hover:underline me-4 md:me-6">Privacy Policy</Link>
        </li>
        <li className="mb-2 sm:mb-0">
            <Link to="/t&c" className="hover:underline me-4 md:me-6">Terms and Condition</Link>
        </li>
        <li className="mb-2 sm:mb-0">
            <Link to="#" className="hover:underline">About</Link>
        </li>
    </ul>
</div>
  </div>
</div>

  );
}

export default GetStarted;

import React from 'react'
import customer from "../../images/customerSupport.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component";

function Support() {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 mx-5 md:mx-16 bg-white rounded-lg border overflow-hidden text-[#127ec8]'>
      <div className='grid justify-items-center md:justify-items-start px-5 md:px-16 py-10 '>
        <p className='text-2xl'>🚀</p>
        <p className='font-bold text-lg mt-8 md:mt-0'>Instant support when you need it.</p>
        <p className='text-center md:text-left mt-6 md:mt-0 md:text-sm font-medium'>Send us a message for assistance with all of your business inquiries. The zippyworld support team is always available to answer your questions.</p>
        <p className='md:text-sm mt-2 text-center md:text-left font-medium'>You can call / whatsapp us on 09039855986 and 09039855986 or send an e-mail to <a href="mailto:zippyworld@macrotelgroup.com">zippyworld@macrotelgroup.com</a></p>
      </div>
      <div>
        <LazyLoadImage src={customer} className='h-full object-cover'/>
      </div>
    </div>
  )
}

export default Support

import React from "react";
import Back from "../../Components/Back";
import StoreAd from "../../Components/storeAdvert";
import SoftPOSForm from "../../Components/EditProfile/SoftPOSActivation";

function SoftPOS() {
  return (
    <div>
      <div className="flex items-center gap-4 pb-4 text-sm font-bold border-b">
        <Back />
        <span>Soft POS</span>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-8">
        <div className="lg:col-span-2">
          <div className="text-sm border rounded-lg">
            <div className="flex items-center justify-between p-4 border-b">
              <p className="font-bold">
                Soft POS Activation status:
                <span className="text-xs p-2 ml-4 bg-[#22AD8020] text-[#22AD80] rounded font-medium">
                  POS Provisioned
                </span>
              </p>
            </div>
            <div className="py-6">
              <SoftPOSForm />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5">
          <StoreAd />
        </div>
      </div>
    </div>
  );
}

export default SoftPOS;

import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import { addNewCustomer } from "../../data/local/reducers/miscellaneous.reducer";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import { showSuccessToast } from "../../utils/api-utils";

function AddNewCustomerForm() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const phoneNumber = data.phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);

  const initialValues = {
    customer_names: "",
    customer_address: "",
    customer_email: "",
    customer_phonenumber: "",
  };

  const validationSchema = Yup.object({
    customer_names: Yup.string().required("Required"),
    customer_address: Yup.string().required("Required"),
    customer_phonenumber: Yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    const formData = {
      ...values,
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
      customer_id: phoneNumber,
    };
    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(addNewCustomer(formData))
        : "";

    if (payload.status_code === "0") {
      resetForm();
      showSuccessToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={values.customer_names}
                type="text"
                label="Company Name:"
                name="customer_names"
              />
              <FormikControl
                control="input"
                value={values.customer_email}
                type="text"
                label="Email:"
                name="customer_email"
              />
              <FormikControl
                control="input"
                value={values.customer_phonenumber}
                type="text"
                label="Phone Number:"
                name="customer_phonenumber"
              />

              <FormikControl
                control="input"
                type="text"
                value={values.customer_address}
                label="Physical Address:"
                name="customer_address"
              />
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddNewCustomerForm;

import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";
import { useSelector, useDispatch } from "react-redux";
import {
  fundWallet,
  userDetails,
  walletTransfer,
} from "../../data/local/reducers/miscellaneous.reducer";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";
import { showErrorToast, showSuccessToast } from "../../utils/api-utils";

function FundWalletForm() {
  const [value, setValue] = useState(false);
  const [wallet, setWallet] = useState("");
  const handleClose = () => setValue(false);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.misc);
  const [recipientName, setRecipientName] = useState("");
  const [formValues, setFormValues] = useState(null);
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);

  //const accountName = '';//users.miscellaneous.firstname + users.miscellaneous.lastname ?? '';

  const initialValues = {
    customer_id: phoneNumber,
    amount: "",
    description: "",
    // account_no: "",
  };

  const validationSchema = Yup.object({
    ///recipient: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    setValue(true);
    const formData = {
      ...values,
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      receiver_id: wallet,
      channel: "web",
      user_type: data.user_type,
      token: token,
    };

    handleClose();
    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(fundWallet(formData))
        : "";
    dispatch(
      fetchUserBalance({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    dispatch(
      fetchTransactionList({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );

    if (payload.status_code === "0") {
      window.open(payload.url, "_blank");
    } else {
      showErrorToast(payload.message);
    }
    resetForm();
  };

  return (
    <div>
      <Loading open={users.loading} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={phoneNumber}
                type="integer"
                label="Recipient wallet number:"
                name="customer_id"
                disabled={true}
              />

              <FormikControl
                control="input"
                value={values.amount}
                type="integer"
                label="Amount:"
                name="amount"
                error={touched.phoneNumber && errors.phoneNumber}
              />

              <FormikControl
                control="input"
                value={values.description}
                type="text"
                label="Description:"
                name="description"
                error={touched.phoneNumber && errors.phoneNumber}
              />
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default FundWalletForm;

import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";
import { useSelector, useDispatch } from "react-redux";
import {
  fibreProduct,
  estatePayment,
} from "../../data/local/reducers/miscellaneous.reducer";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";
import { showSuccessToast } from "../../utils/api-utils";

function IndoorFibreForm(props) {
  const [value, setValue] = useState(false);
  const [meterNumbers, setMeterNumbers] = useState("");
  const handleClose = () => setValue(false);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.misc);
  const [formValues, setFormValues] = useState(null);
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [fibreList, setFibreList] = useState([]);

  //const accountName = '';//users.miscellaneous.firstname + users.miscellaneous.lastname ?? '';

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const formData = {
        estate_code: props.dataReceived,
        service_code: "ZWESP1990",
      };
      const { payload } = await dispatch(fibreProduct(formData));
      setFibreList(payload.result[0]);
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch, props.dataReceived]);

  const initialValues = {
    phonenumber: "",
    alternate_phonenumber: "",
  };

  const validationSchema = Yup.object({
    phonenumber: Yup.string().required("phonenumber"),
  });

  const handleSubmit = (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    resetForm();
  };

  const onSubmit = async (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    const formData = {
      ...formValues,
      card_identity: meterNumbers,
      product_code: fibreList.product_code,
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      estate_code: props.dataReceived,
      channel: "web",
      user_type: data.user_type,
      token: token,
      customer_id: phoneNumber,
    };

    handleClose();
    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(estatePayment(formData))
        : "";
    dispatch(
      fetchUserBalance({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    dispatch(
      fetchTransactionList({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );

    if (payload.status_code === "0") {
      showSuccessToast(payload.message);
      resetForm();
    }
  };

  return (
    <div>
      <Loading open={users.loading} />
      <SecurityAnswerModal
        open={value}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={values.phonenumber}
                type="text"
                label="Phone Number:"
                name="phonenumber"
              />
              <FormikControl
                control="input"
                type="text"
                label="Product"
                name="product"
                value={fibreList.product}
                disabled
              />

              <FormikControl
                control="input"
                value={fibreList.price}
                type="integer"
                label="Price:"
                name="amount"
                error={touched.phoneNumber && errors.phoneNumber}
                disabled
              />

              <FormikControl
                control="input"
                value={values.alternate_phonenumber}
                type="text"
                label="Alternate Phone Number:"
                name="alternate_phonenumber"
              />
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Subscribe" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default IndoorFibreForm;

import React, { useState, useEffect } from "react";
import { fetchUserBalance } from "../data/local/reducers/user.reducer";
import { generateTransactionId } from "../data/remote/dtos/login.dto";
import { AmountFormatter } from "../utils/constant";
import { useDispatch, useSelector } from "react-redux";

const DownloadTemplate = () => {
  const dispatch = useDispatch();
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const [qrbal, setQRbal] = useState(true);
  const [balance, setBalance] = useState("");
  const changeBalance = () => setQRbal(!qrbal);

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(
        fetchUserBalance({
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: loginProfile.token,
        })
      );

      setBalance(payload.amount);
    };
    fetchData();
  }, [dispatch, loginProfile.token, phoneNumber]);

  return (
    <div className="flex items-center justify-between p-4 border-b">
      <p className="font-bold opacity-50">
        Wallet balance:{<AmountFormatter amount={balance} currency="NGN" />}
      </p>
      {/* <p className='text-[#127ec8]'><a href={sample_bulk_airtime} download>[ Download upload template ]</a></p> */}
    </div>
  );
};

export default DownloadTemplate;

import React from "react";
import RecentTransactions from "../../Components/recentTransactions";
import StoreAd from "../../Components/storeAdvert";
import Back from "../../Components/Back";
//import BulkVendForm from '../../Components/BulkOperation/BulkVendForm'
import { useSelector } from "react-redux";
import { AmountFormatter } from "../../utils/constant";
import BulkVendForm from "../../Components/Exams/bulkVendForm";
import DownloadTemplateJamb from "../../Components/DownloadTemplateJamb";

function BulkVend() {
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const userBalance = userBalanceProfile.amount.toLocaleString() || "0.00";

  return (
    <div>
      <div className="flex items-center gap-4 pb-4 text-sm font-bold border-b">
        <Back />
        <span>Bulk Jamb</span>
      </div>
      <div className="grid grid-cols-1 gap-5 mt-5 lg:grid-cols-3">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 gap-5">
            <div className="text-sm border rounded-lg">
    <DownloadTemplateJamb/>
              <div className="grid grid-cols-1 py-4">
                <BulkVendForm />
              </div>
            </div>
          </div>
        </div>
        <div className="grid gap-5">
          <StoreAd />
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default BulkVend;

import React from "react";
import RecentTransactions from "../../Components/recentTransactions";
import StoreAd from "../../Components/storeAdvert";
import Back from "../../Components/Back";
import ShagoElectricityForm from "../../Components/Electricity/ShagoElectricity";
import DownloadTemplate from "../../Components/DownloadTemplate";

function ShagoElectricity() {
  return (
    <div>
      <div className="flex items-center gap-4 pb-4 text-sm font-bold border-b">
        <Back />
        <span>
          Electricity <span className=" opacity-30">- Zippy Diamond</span>
        </span>
      </div>
      <div className="grid grid-cols-1 gap-5 mt-5 lg:grid-cols-3">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 gap-5">
            <div className="text-sm border rounded-lg">
              <DownloadTemplate/>
              <div className="grid grid-cols-1 py-4">
                <ShagoElectricityForm />
              </div>
            </div>
          </div>
        </div>
        <div className="grid gap-5">
          <StoreAd />
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default ShagoElectricity;

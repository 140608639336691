import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";
import { useSelector, useDispatch } from "react-redux";
import {
  userDetails,
  walletTransfer,
  getBanks,
  topUpWallet,
} from "../../data/local/reducers/miscellaneous.reducer";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";
import { showErrorToast, showSuccessToast } from "../../utils/api-utils";

function TopUpWalletForm() {
  const [value, setValue] = useState(false);
  const [wallet, setWallet] = useState("");
  const handleClose = () => setValue(false);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.misc);
  const [recipientName, setRecipientName] = useState("");
  const [formValues, setFormValues] = useState(null);
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [banks, setBanks] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading state to true before fetching banks
      const { payload } = await dispatch(getBanks());
      setBanks(payload);

      setIsLoading(false); // Set loading state to false after banks are fetched
    };
    fetchData();
  }, [dispatch]);

  const banklist = banks ? [...banks] : [];

  const handleWalletChange = async (event) => {
    const walletPhoneNumber = event.target.value;
    setWallet(walletPhoneNumber);
    if (walletPhoneNumber.length === 11) {
      const { payload } = await dispatch(
        userDetails({
          phonenumber: walletPhoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: token,
        })
      );
      const { names } = payload;
      setRecipientName(names);
    }
  };

  const initialValues = {
    bank: "",
    amount: "",
    reference_id: "",
    // account_no: "",
  };

  const validationSchema = Yup.object({
    reference_id: Yup.string().required("Required"),
    amount: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    resetForm();
  };

  const onSubmit = async (values, { resetForm }) => {
    setFormValues(values); // Store the form values in the state
    setValue(true);
    const formData = {
      ...formValues,
      phonenumber: phoneNumber,
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
    };

    handleClose();
    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(topUpWallet(formData))
        : "";
    dispatch(
      fetchUserBalance({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    dispatch(
      fetchTransactionList({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );

    if (payload.status_code === "0") {
      resetForm();
      showSuccessToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={isLoading} />
      <Loading open={users.loading} />
      <SecurityAnswerModal
        open={value}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={phoneNumber}
                type="integer"
                label="Recipient wallet number:"
                name="sender_id"
                disabled={true}
              />
              <FormikControl
                control="selectBank"
                options={banklist}
                label="Select bank:"
                name="bank"
                value={values.bankName}
                onChange={(event) => {
                  handleChange(event);
                  //   verifyAccountNumber(
                  //     values.account_number,
                  //     event.target.value
                  //   );
                }}
                error={touched.bank_code && errors.bank_code}
              />

              <FormikControl
                control="input"
                value={values.amount}
                type="integer"
                label="Amount:"
                name="amount"
                error={touched.amount && errors.amount}
              />

              <FormikControl
                control="input"
                value={values.payment_reference}
                type="text"
                label="Payment Reference:"
                name="reference_id"
                error={touched.reference_id && errors.reference_id}
              />
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default TopUpWalletForm;

import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import { useSelector, useDispatch } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import {
  fetchSecurityQuestions,
  generateToken,
  resetSecurityDetails,
} from "../../data/local/reducers/user.reducer";
import { showSuccessToast } from "../../utils/api-utils";

function ChangePinForm() {
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const email = loginProfile.user_detail[0].email;
  const token = useSelector((state) => state.user.loginProfile.token);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);
  const [questions, setQuestions] = useState("");
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const [verifyStatus, setVerifyStatus] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(fetchSecurityQuestions());
      setQuestions(payload["result"]);
    };
    fetchData();
  }, [dispatch]);

  const securityOptions = questions ? [...questions] : [];
  const verifyPinValue = async (event) => {
    const pass = event.target.value;
    if (pass.length === 4) {
      const { payload } = await dispatch(
        generateToken({
          passcode: pass,
          operation_type: "SECURITY-ANSWER",
          email: email,
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          user_type: data.user_type,
          channel: "web",
          token: token,
        })
      );

      setVerifyStatus(payload.status_code);
      if (payload.status_code === "0") {
        showSuccessToast(payload.message);
      }
    }
  };

  const initialValues = {
    securityQuestion: "",
    securityAnswer: "",
    securityToken: "",
  };

  const validationSchema = Yup.object({
    securityAnswer: Yup.string().required("Required"),
    securityToken: Yup.string().required("securityToken"),
  });

  const onSubmit = async (values, { resetForm }) => {
    const { securityQuestion, securityAnswer, securityToken } = values;
    const formData = {
      security_token: securityToken,
      security_answer: securityAnswer,
      secure_id: securityQuestion,
      update_type: "SECURITY-ANSWER",
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
      phonenumber: phoneNumber,
    };

    const { payload } =
      formData.security_answer !== null || undefined
        ? await dispatch(resetSecurityDetails(formData))
        : "";

    if (payload.status_code === "0") {
      resetForm();
      showSuccessToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            {verifyStatus !== "0" ? (
              <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormikControl
                  control="input"
                  value={values.pass_code}
                  type="text"
                  label="Enter Pin:"
                  name="pass_code"
                  onChange={verifyPinValue}
                />
              </div>
            ) : (
              <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormikControl
                  control="selected"
                  options={securityOptions ?? ""}
                  label="Security question"
                  name="securityQuestion"
                  value={values.securityQuestion}
                  onChange={handleChange}
                  error={touched.securityQuestion && errors.securityQuestion}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Security answer"
                  name="securityAnswer"
                  value={values.securityAnswer}
                  onChange={handleChange}
                  error={touched.securityAnswer && errors.securityAnswer}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Security Token"
                  name="securityToken"
                  value={values.securityToken}
                  onChange={handleChange}
                  error={touched.securityToken && errors.securityToken}
                />
              </div>
            )}

            <div className="px-4 text-sm items-center text-slate-600 mb-10 flex gap-3">
              <input
                type="checkbox"
                name="Save beneficiary"
                id="save_beneficiary"
              />
              <span>Save as beneficiary</span>
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ChangePinForm;

import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import { jambSwap } from "../../data/local/reducers/miscellaneous.reducer";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import { showErrorToast } from "../../utils/api-utils";
import { jambReceipt } from "../../utils/constant";

function RetrievePinForm() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;

  const initialValues = {
    phonenumber: phoneNumber,
    candidate_phonenumber: "",
    exam_type: "",
    designate_id: "",
  };

  // Print single transaction
  const handleSinglePrint = async (Content) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    // Use html2canvas to capture the entire content of printWindow
    printWindow.document.write(Content);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
    }, 300);
  };

  const retrieveOptions = [
    { key: "Select an option", value: "" },
    { key: "UTME", value: "UTME" },
    { key: "DIRECT_ENTRY", value: "DIRECT_ENTRY" },
  ];

  const validationSchema = Yup.object({
    candidate_phonenumber: Yup.string().required("Required"),
    exam_type: Yup.string().required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    const formData = {
      ...values,
      transaction_id: generateTransactionId(),
      channel: "web",
      token: token,
    };
    const { payload } = await dispatch(jambSwap(formData));

    if (payload.status_code === "0") {
      resetForm();
      const htmlSingleContent = jambReceipt(payload);
      handleSinglePrint(htmlSingleContent);
    } else {
      showErrorToast(payload["result"].message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={values.phonenumber}
                type="integer"
                label="Recipient wallet number:"
                name="phonenumber"
                disabled={true}
              />
              <FormikControl
                control="input"
                value={values.designated_id}
                type="text"
                label="Designated Id:"
                name="designate_id"
              />
              <FormikControl
                control="select"
                options={retrieveOptions}
                label="Exam Type"
                name="exam_type"
                values={values.exam_type}
                onChange={handleChange}
                error={touched.exam_type && errors.exam_type}
              />
              <FormikControl
                control="input"
                value={values.candidate_phonenumber}
                type="text"
                label="Candidate Phone Number:"
                name="candidate_phonenumber"
              />
            </div>

            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RetrievePinForm;

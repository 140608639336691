import React from "react";
import icon from "../images/store icon.svg";

function StoreAd() {
  return (
    // <div className='storeAd p-5 text-sm text-white grid grid-cols-1 rounded-lg'>
    //   <div className='flex items-center'>
    //     <img src={icon} alt="" className='w-10' />
    //   <div className='p-4'>
    //     <p className='font-bold'>Store name</p>
    //     <p>Set up an online store to givekjkfiyutytyy your business the online presence it deserves.</p>
    //   </div>
    //   </div>
    // </div>
    <div className="flex flex-col">
      <div className="store-bg rounded-t-lg relative h-[150px] md:h-[100px]">
        <img src={icon} alt="" className="absolute bottom-4 left-4" />
      </div>
      <div className="px-4 py-6 md:py-4 border rounded-b-lg">
        Set up an online store to give your business the online presence it
        deserves.
      </div>
    </div>
  );
}

export default StoreAd;

import React, { useState } from "react";
import RecentTransactions from "../../Components/recentTransactions";
import StoreAd from "../../Components/storeAdvert";
import Back from "../../Components/Back";
import SavedBeneficiaryButton from "../../Components/savedBeneficiaryButton";
import BankBeneficiaryModal from "../../Components/Beneficiary by service/bankBeneficiaries";
import PrivateElectricityForm from "../../Components/PrivateEstates/electrictySubscription";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AmountFormatter } from "../../utils/constant";
import DownloadTemplate from "../../Components/DownloadTemplate";

function PrivateElectricity() {

  const [value, setValue] = useState(false);
  const closeBankList = () => setValue(false);
  const location = useLocation();
  const dataReceived = new URLSearchParams(location.search).get("data");

  return (
    <div>
      <BankBeneficiaryModal
        launchBankList={value}
        closeBankList={closeBankList}
      />
      <div className="flex items-center text-sm font-bold gap-4 border-b pb-4">
        <Back />
        <span>Estate Electricity</span>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 gap-5">
            <div className="rounded-lg border text-sm">
              <DownloadTemplate />
              <div className="py-4 grid grid-cols-1">
                <PrivateElectricityForm dataReceived={dataReceived} />
              </div>
            </div>
          </div>
        </div>
        <div className="grid gap-5">
          <StoreAd />
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default PrivateElectricity;

import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";

function SelectElectricBeneficiary(props) {
  const { label, name, options, ...rest } = props;
  return (
    <div className=" mb-3">
      <label className=" text-pri-text block" htmlFor={name}>
        {label}
      </label>
      <Field
        as="select"
        className="mb-1 w-full border rounded-md border-gray-200 p-3 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
        id={name}
        name={name}
        {...rest}
      >
        <option value="">Select Beneficiaries</option>

        {options.map((option, index) => {
          return (
            <option
              className="space-y-10"
              key={index}
              value={option.customer_number + '|' + option.card_identity + '|' + option.address + '|' + option.email + '|' + option.beneficiary_name  }
            >
              {option.beneficiary_name}
            </option>
          );
        })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  );
}

export default SelectElectricBeneficiary;

import React from 'react'
import OtpForm from '../../Components/Signup/OtpForm'

function Otp() {
  return (
    <div className='bg-bg-main h-screen'>
       <div className='grid lg:grid-cols-2'>
        <div>
          <OtpForm />
        </div>
        <div className='hidden md:block'>
        <div className='h-screen grid content-center p-2'>
          <div className='reg h-96 p-16 text-center text-sm text-white grid content-end'>
            <div>
            <span className='bg-white/25 px-5 py-3 rounded-full text-xs'>Photo credit: Seyi Owolabi</span>
            <p className='mt-5'>Make your DSTV, GoTV and Startimes subscriptions on zippyworld to enjoy the 2022 FIFA WORLD CUP</p>
            </div>
          </div>
        </div>
        </div>
       </div>
    </div>
  )
}

export default Otp
import React, { useState, useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from "react-redux";
import {
  estatesHistory,
} from "../../data/local/reducers/miscellaneous.reducer";




export default function TokenGeneration(props) {
    const dispatch = useDispatch();
    const [estateList, setEstateList] = useState([]);
    const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
    const phoneNumber = data.phonenumber;
  
    useEffect(() => {
      const fetchData = async () => {
        const formData = {
          phonenumber: phoneNumber,
          estate_code: props.dataReceived,
        };
        const { payload } = await dispatch(estatesHistory(formData));
        setEstateList(payload.result);
      };
      fetchData();
    }, [dispatch]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const columns = [
    { id: 'name', label: 'Name', minWidth: 150 },
    {
      id: 'entering_mode',
      label: 'Entering Mode',
      minWidth: 150,
    },
    {
      id: 'number_plate',
      label: 'Number Plate',
      minWidth: 150,
    },
    {
        id: 'token',
        label: 'Token',
        minWidth: 150,
      },
      {
        id: 'STATUS',
        label: 'Status',
        minWidth: 150,
      },

  ];
  
  function createData(name, entering_mode, number_plate, token, STATUS) {
    return { name, entering_mode, number_plate, token, STATUS };
  }
  
  const apiData = estateList;
  
  const rows = apiData.map((data) => createData(data.name, data.entering_mode, data.number_plate, data.token, data.STATUS));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 400 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: '#E8F3FA',
                    color: '#127EC8',
                    borderBottom: '1px solid #127EC830',
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.phone}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import {
  jambProfiling,
  jambType,
} from "../../data/local/reducers/miscellaneous.reducer";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import { useNavigate } from "react-router-dom";

function VendPinForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const [exams, setExams] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(jambType());
      setExams(payload["result"]);
    };
    fetchData();
  }, [dispatch]);

  const examList = exams ? [...exams] : [];

  const initialValues = {
    sender_id: phoneNumber,
    candidate_phonenumber: "0",
    exam_types: "",
    // exam_code: "",
    designate_id: "",
    payment_option: "",
    is_mock_candidate: "",
  };

  const vendOptions = [
    { key: "Select an option", value: "" },
    { key: "Transfer", value: "Transfer" },
    { key: "Wallet", value: "Wallet" },
    { key: "Cash", value: "Cash" },
  ];

  const retrieveOptions = [
    { key: "Select Mock Status", value: "" },
    { key: "Mock", value: "true" },
    { key: "No Mock", value: "false" },
  ];

  const validationSchema = Yup.object({
    //product: Yup.string().required("Required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const { exam_types } = values;
    const formData = {
      ...values,
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
      payment_option: values.payment_option,
      phonenumber: data.phonenumber,
      exam_type: exam_types.split("|")[0],
      exam_code: exam_types.split("|")[1],
    };

  
    const { payload } = await dispatch(jambProfiling(formData));
    if (payload.status_code === "0") {
      resetForm();
      //navigate("/dashboard");
      navigate("/buyPin", {
        state: {
          payment_option: values.payment_option,
          ref: payload["reference_number"],
          exam_code: exam_types.split("|")[1],
          exam_type: exam_types.split("|")[0],
          info: payload["result"],
          amount: exam_types.split("|")[2],
        },
      });
      //showSuccessToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={values.sender_id}
                type="integer"
                label="Recipient wallet number:"
                name="sender_id"
                disabled={true}
              />
              <FormikControl
                control="input"
                value={values.designated_id}
                type="text"
                label="Designated Id:"
                name="designate_id"
              />
              <FormikControl
                control="selectExam"
                options={examList}
                label="Select Exam:"
                name="exam_types"
                value={values.exam_list}
                //   onChange={(event) => {
                //     handleChange(event);
                //     verifyAccountNumber(
                //       values.account_number,
                //       event.target.value
                //     );
                //   }}
              />
              <FormikControl
                control="select"
                options={retrieveOptions}
                label="Exam Type"
                name="is_mock_candidate"
                values={values.is_mock_candidate}
                onChange={handleChange}
                error={touched.is_mock_candidate && errors.is_mock_candidate}
              />
                <FormikControl
                  control="select"
                  options={vendOptions}
                  label="Payment Options"
                  name="payment_option"
                  values={values.payment_option}
                  onChange={handleChange}
                />

            </div>
            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default VendPinForm;

import React from "react";
import NavCard from "../../Components/navCard";
import banktransferimg from "../../images/Banktransfer.png";
import wallettransferimg from "../../images/Wallettransfer.png";
import fundwallet from '../../images/fundWallet.svg';
import topUpWallet from '../../images/topUpWallet.svg';
import RecentTransactions from "../../Components/recentTransactions";
import StoreAd from "../../Components/storeAdvert";
import Back from "../../Components/Back";
import { Link } from "react-router-dom";

function MoneyTransfer() {
  return (
    <div>
      <div className="flex items-center text-sm font-bold gap-4 border-b pb-4">
        <Back />
        Money transfer.
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
            <Link to="/banktransfer">
              <NavCard
                serviceImg={banktransferimg}
                serviceName="Bank Transfer"
              />
            </Link>
            <Link to="/wallettransfer">
              <NavCard
                serviceImg={wallettransferimg}
                serviceName="Wallet Transfer"
              />
            </Link>
            <Link to="/fundWallet">
              <NavCard serviceImg={fundwallet} serviceName="Fund Wallet" />
            </Link>
            <Link to="/topUpWallet">
              <NavCard
                serviceImg={topUpWallet}
                serviceName="Top Up Wallet"
              />
            </Link>
          </div>
          <StoreAd />
        </div>
        <div>
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default MoneyTransfer;

import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { APIService } from "../../remote/services/api.services";
import { retrieveFromLocalStorage } from "../../../utils/constant";
import { showErrorToast, showSuccessToast } from "../../../utils/api-utils";

const initialState = {
  users: null,
  loading: false,
  error: null,
  isAuthenticated: false,
  ...retrieveFromLocalStorage([
    "loginProfile",
    "verifyBvnProfile",
    "fetchSecurityQuestionProfile",
    "UserBalanceProfile",
    "fetchTransactionProfile",
    "userCommissionProfile",
    "qrBalanceProfile",
    "fetchTransactionFlowProfile",
    "generateTokenProfile",
    "token",
  ]),
};

const saveToLocalStorage = (key, data) => {
  sessionStorage.setItem(key, data);
  // data;
};

export const login = createAsyncThunk("user/login", async (userCredentials) => {
  const profile = await APIService.login(userCredentials);
  const response = await profile.data;
  const token = await profile.data.token;
  saveToLocalStorage("loginProfile", JSON.stringify(response));
  saveToLocalStorage("token", JSON.stringify(token)); // Save the session in local storage
  return response;
});

export const verifyBVN = createAsyncThunk(
  "user/verifyBVN",
  async (verifyBVNCredentials) => {
    const bvnVerify = await APIService.verifyBVN(verifyBVNCredentials);
    const response = await bvnVerify.data;

    saveToLocalStorage("verifyBvnProfile", JSON.stringify(response.result)); // Save the session in local storage

    return response;
  }
);
export const sendOTP = createAsyncThunk(
  "user/sendOTP",
  async (sendOTPCredentials) => {
    const otpSend = await APIService.sendOTP(sendOTPCredentials);
    const response = await otpSend.data;
    return response;
  }
);

export const verifyOTP = createAsyncThunk(
  "user/verifyOTP",
  async (verifyOTPCredentials) => {
    const otpVerify = await APIService.verifyOTP(verifyOTPCredentials);
    const response = await otpVerify.data;
    return response;
  }
);

export const generateToken = createAsyncThunk(
  "user/generateToken",
  async (generateTokenCredentials) => {
    const verifyToken = await APIService.generateToken(
      generateTokenCredentials
    );
    const response = await verifyToken.data;
    saveToLocalStorage("generateTokenProfile", JSON.stringify(response)); // Save the session in local storage

    return response;
  }
);

//validateEstateMembership

//resetSecurityDetails
export const resetSecurityDetails = createAsyncThunk(
  "user/resetSecurityDetails",
  async (resetSecurityDetailsCredentials) => {
    const resetSecurityDetailsData = await APIService.resetSecurityDetails(
      resetSecurityDetailsCredentials
    );
    const response = await resetSecurityDetailsData.data;
    return response;
  }
);
export const fetchSecurityQuestions = createAsyncThunk(
  "user/fetchSecurityQuestions",
  async () => {
    const questionsFetch = await APIService.fetchSecurityQuestions();
    const response = await questionsFetch.data;
    saveToLocalStorage(
      "fetchSecurityQuestionProfile",
      JSON.stringify(response)
    );
    return response;
  }
);

export const signUp = createAsyncThunk(
  "user/signUp",
  async (signUpCredentials) => {
    const otpSend = await APIService.signUp(signUpCredentials);
    const response = await otpSend.data;
    return response;
  }
);

export const fetchUserBalance = createAsyncThunk(
  "user/fetchUserBalance",
  async (phoneNumber) => {
    const userBalance = await APIService.fetchUserBalance(phoneNumber);
    const response = await userBalance.data;
    saveToLocalStorage("UserBalanceProfile", JSON.stringify(response));
    return response;
  }
);

export const fetchWalletHistory = createAsyncThunk(
  "user/fetchWalletHistory",
  async (walletHistoryCredentials) => {
    const fetchWalletHistory = await APIService.fetchWalletHistory(
      walletHistoryCredentials
    );
    const response = await fetchWalletHistory.data;
    return response;
  }
);

export const fetchUserCommission = createAsyncThunk(
  "user/fetchUserCommission",
  async (phoneNumber) => {
    const userCommission = await APIService.fetchUserCommission(phoneNumber);
    const response = await userCommission.data;
    saveToLocalStorage("userCommissionProfile", JSON.stringify(response));
    return response;
  }
);

export const fetchQrBalance = createAsyncThunk(
  "user/fetchQrBalance",
  async (phoneNumber) => {
    const qrBalance = await APIService.fetchQrBalance(phoneNumber);
    const response = await qrBalance.data;
    saveToLocalStorage("qrBalanceProfile", JSON.stringify(response));
    return response;
  }
);

export const fetchTransactionList = createAsyncThunk(
  "user/fetchTransactionList",
  async (phoneNumber) => {
    const fetchTransactionList = await APIService.fetchTransactionList(
      phoneNumber
    );
    const response = await fetchTransactionList.data;
    saveToLocalStorage("fetchTransactionProfile", JSON.stringify(response));
    return response;
  }
);

export const fetchFullTransactionList = createAsyncThunk(
  "user/fetchFullTransactionList",
  async (phoneNumber) => {
    const fetchFullTransactionList = await APIService.fetchFullTransactionList(
      phoneNumber
    );
    const response = await fetchFullTransactionList.data;
    return response;
  }
);

export const fetchTransactionFlow = createAsyncThunk(
  "user/fetchTransactionFlow",
  async (phoneNumber) => {
    const fetchTransactionFlow = await APIService.fetchTransactionFlow(
      phoneNumber
    );
    const response = await fetchTransactionFlow.data;
    saveToLocalStorage("fetchTransactionFlowProfile", JSON.stringify(response));
    return response;
  }
);

const logouts = () => {
  sessionStorage.removeItem("users");
  sessionStorage.removeItem("loginProfile");
  sessionStorage.removeItem("verifyBvnProfile");
  sessionStorage.removeItem("fetchSecurityQuestionProfile");
  sessionStorage.removeItem("UserBalanceProfile");
  sessionStorage.removeItem("fetchTransactionProfile");
  sessionStorage.removeItem("userCommissionProfile");
  sessionStorage.removeItem("qrBalanceProfile");
  sessionStorage.removeItem("fetchTransactionFlowProfile");
  sessionStorage.removeItem("estateMeterValidationProfile");
  sessionStorage.removeItem("fetchJambTransactionProfile");
};

export const logout = createAsyncThunk("user/logouts", async () => {
  await logouts();
});

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.users = null;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.isAuthenticated = true;
          state.users = action.payload;
          state.token = action.payload.token;
          state.loginProfile = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.users = null;
        state.error = showErrorToast(action.error.message);
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
        state.users = null;
      })
      .addCase(sendOTP.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showSuccessToast("Token has been sent to your Phonenumber or Email");
        }
        state.loading = false;
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(verifyBVN.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
          state.verifyBvnProfile = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(generateToken.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
          state.generateTokenProfile = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(resetSecurityDetails.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(fetchSecurityQuestions.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
          state.fetchSecurityQuestionProfile = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(fetchUserBalance.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
          state.userBalanceProfile = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          // showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          // showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(fetchUserCommission.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
          state.userCommissionProfile = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(fetchQrBalance.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
          state.qrBalanceProfile = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(fetchTransactionList.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
          state.fetchTransactionProfile = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(fetchFullTransactionList.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(fetchTransactionFlow.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
          state.fetchTransactionFlowProfile = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(fetchWalletHistory.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.users = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addMatcher(
        isAnyOf(
          sendOTP.pending,
          verifyOTP.pending,
          verifyBVN.pending,
          fetchSecurityQuestions.pending,
          fetchUserBalance.pending,
          fetchTransactionList.pending,
          fetchUserCommission.pending,
          fetchQrBalance.pending,
          fetchTransactionFlow.pending,
          generateToken.pending,
          resetSecurityDetails.pending,
          fetchFullTransactionList.pending,
          fetchWalletHistory.pending
        ),
        (state) => {
          state.loading = true;
          state.users = null;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          sendOTP.rejected,
          verifyOTP.rejected,
          verifyBVN.rejected,
          fetchSecurityQuestions.rejected,
          fetchUserBalance.rejected,
          fetchTransactionList.rejected,
          fetchUserCommission.rejected,
          fetchQrBalance.rejected,
          fetchTransactionFlow.rejected,
          generateToken.rejected,
          resetSecurityDetails.rejected,
          fetchFullTransactionList.rejected,
          fetchWalletHistory.rejected
        ),
        (state, action) => {
          state.loading = false;
          state.users = null;
          state.error = showErrorToast(action.error.message);
        }
      );
  },
});

//export const { setCurrentPost } = userSlice.actions;
export const userReducer = userSlice.reducer;

import React from "react";
import RecentTransactions from "../../Components/recentTransactions";
import StoreAd from "../../Components/storeAdvert";
import Back from "../../Components/Back";
import { Link } from "react-router-dom";

function InvoiceOption() {

  const invoiceOptions = [
    {
      id: 1,
      name: "Create Profile",
      link: "/createProfile",
    },
    {
      id: 2,
      name: "Generate Invoice",
      link: "/generateInvoice",
    },
    {
      id: 3,
      name: "History",
      link: "/invoiceHistory",
    },
    {
      id: 5,
      name: "Upload Logo",
      link: "/uploadLogo",
    },
    {
      id: 6,
      name: "Add Product",
      link: "/saveProduct",
    },
    // {
    //   id: 7,
    //   name: "Invoice Report",
    //   link: "/invoiceReport",
    // },
  ];

  return (
    <div>
      <div className="flex items-center text-sm font-bold gap-4 border-b pb-4">
        <Back />
        Invoice Options
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5">
        <div className="lg:col-span-2">
          {invoiceOptions.map((invoice) => (
            // <div key={estate.id}>
            //   <Link to={`/wallettransfer/${data}`}>
            //   <NavCard  serviceImg = {estate.image} serviceName = {estate.name} />
            //   </Link>
            // </div>

            <div className="w-full mb-5 text-sm font-semibold" key={invoice.id}>
              <Link
                to={invoice.link}
                className="flex justify-between p-4 hover:bg-[#127EC810] hover:text-[#127EC8] hover:rounded-md hover:px-6 transition duration-300 "
              >
                <div>{invoice.name}</div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"
                      fill="rgba(0,0,0,1)"
                    ></path>
                  </svg>
                </div>
              </Link>
            </div>
          ))}

          <StoreAd />
        </div>
        <div>
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default InvoiceOption;

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import { exportToExcel } from "../../utils/exportUtils";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Loading from "../SelectValue/Loading";
import {
  fetchJambTransactionList,
  waecHistory,
} from "../../data/local/reducers/miscellaneous.reducer";
import { jambTransactionReceipt, waecTransactionReceipt } from "../../utils/constant";

const WaecTransactionTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [receiptData, setReceiptData] = useState([]);
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const dispatch = useDispatch();
  const users = useSelector((state) => state.misc);
  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(waecHistory(phoneNumber));

      if (payload["status_code"] == "0") {
        setReceiptData(payload?.results ?? []);
      }
    };
    fetchData();
  }, [dispatch, phoneNumber]);
  // Search transaction
  const [searchText, setSearchText] = useState("");


  const listTransaction = receiptData.filter((transaction) =>
    (transaction.candidate_names || "").toLowerCase().includes(searchText.toLowerCase())
  );

  const limitedTransactions = listTransaction.slice(startIndex, endIndex);

  const csvData = listTransaction.map((transaction) => ({
    "Candidate Names": transaction.candidate_names || "Not Available",
    "Token Type": transaction.token_type || "Not Available",
    "Amount": transaction.amount_charge || "Not Available",
    "Status": transaction.status == "0" ? "Successful" : "Failed",
    "Pin": transaction.pin || "Not Available",
    "Email": transaction.email || "Not Available",
    "Date": transaction.time_in || "Not Available",
  }));

  const handleExportExcel = () => {
    exportToExcel(listTransaction);
  };

  const handleExportPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape", // Set orientation to landscape
      // unit: "mm", // Set unit to millimeters
      format: "a4", // Set format to A4
    });
    const tableData = listTransaction.map((transaction) => [
      transaction.candidate_names ?? "",
      transaction.token_type,
      transaction.amount,
      transaction.status,
      transaction.pin,
      transaction.email,
      transaction.time_in,
    ]);
    doc.autoTable({
      head: [
        [
          "Candidate Names",
          "Token Type",
          "Amount",
          "Status",
          "Pin",
          "Email",
          "Date",
        ],
      ],
      body: tableData,
    });
    doc.save("transactions.pdf");
  };
  // Print single transaction
  const handleSinglePrint = async (Content) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(Content);
    printWindow.document.close();
    setTimeout(() => {
      printWindow.print();
    }, 300);
  };

  return (
    <div className="border rounded-md">
      <Loading open={users.loading} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 border-b p-4 items-center">
        <div className="relative mt-1">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search candidate phonenumber..."
            className="block p-2 pl-10 text-sm text-black border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="text-xs flex gap-4 justify-center md:justify-end">
          <span className=" font-semibold">Export:</span>
          <div className="flex opacity-80 font-medium">
            <span
              className="pr-2 hover:text-[#127EC8] cursor-pointer"
              onClick={handleExportExcel}
            >
              Excel
            </span>
            <CSVLink
              data={csvData}
              filename={"transactions.csv"}
              className="px-2 hover:text-[#127EC8] cursor-pointer"
            >
              CSV
            </CSVLink>
            <span
              className="px-2 hover:text-[#127EC8] cursor-pointer"
              onClick={handleExportPDF}
            >
              PDF
            </span>
            <span
              className="pl-2 hover:text-[#127EC8] cursor-pointer"
              // onClick={}
            >
              Print
            </span>
          </div>
        </div>
      </div>
      <div className="rounded-t-md max-w-full overflow-x-scroll m-4 print-table">
        <table className="w-full text-sm text-left">
          <thead className="text-[#127EC8] bg-[#127EC830]">
            <tr>
              <th className="px-6">Candidate Names</th>
              <th className="px-6">Token Type</th>
              <th className="px-6">Amount</th>
              <th className="px-6">Status</th>
              <th className="px-6 text-center">Pin</th>
              <th className="px-6">Email</th>
              <th className="px-6">Date</th>
              <th className="px-6 text-center">Download</th>
            </tr>
          </thead>
          <tbody className="">
            {limitedTransactions.map((object, index) => (
              <tr
                className="bg-white border-b text-black text-[13px]"
                key={index}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  {object.candidate_names}
                </td>

                <td className="px-6 py-4 font-medium whitespace-nowrap">
                  {object.token_type}
                </td>
                <td className="px-6 py-4 font-medium whitespace-nowrap">
                  {object.amount_charge}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {object.status == "0" ? "Successful" : "failed"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{object.pin}</td>
                <td className="px-6 py-4 whitespace-nowrap">{object.email}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {object.time_in}
                </td>

                <td className="pr-10 print-more-info mx-auto text-center text-blue-700">
                  <button
                    onClick={() =>
                      handleSinglePrint(
                        waecTransactionReceipt(
                          object,
                          phoneNumber
                        )
                      )
                    }
                    className="rounded-md bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 transition-colors duration-300 ease-in-out focus:outline-none"
                  >
                    Download
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between p-4 text-xs font-semibold gap-11">
        <div className="opacity-60">
          {listTransaction.length > 0
            ? `Showing transactions ${startIndex + 1} - ${Math.min(
                endIndex,
                listTransaction.length
              )}`
            : "No transactions found"}
        </div>
        <div className="flex items-center overflow-x-auto">
          <span>Page:</span>
          <span className="flex gap-2 ml-2">
            {Array.from({ length: Math.ceil(listTransaction.length / 10) }).map(
              (_, index) => (
                <span
                  key={index}
                  className={`${
                    currentPage === index + 1
                      ? "bg-[#127ec8] text-white rounded-full"
                      : ""
                  } w-6 h-6 grid place-content-center cursor-pointer`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </span>
              )
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default WaecTransactionTable;

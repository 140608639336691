import React from 'react';
import Box from '@mui/material/Box';
import close from '../../images/close-icon.svg'
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '350px',
    bgcolor: '#ffffff',
    p: 1,
    borderRadius: '10px'
  };

  const margin = {
    m: 2,
  };
  
  export default function BankBeneficiaryModal({launchBankList, closeBankList}) {
  
    return (
      <div className=''>
        <Modal
          sx={margin}
          open={launchBankList}
          onClose={closeBankList}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >

          <Box sx={style}>
              <div className='text-sm'>
                {/* header */}
                {/* <div className='py-4 grid grid-cols-2 items-center border-b'>
                  <div className=' font-bold pl-2'>Saved Beneficiaries</div>
                  <div className='grid justify-items-end pr-2'>
                    <img src={close} alt="" className='cursor-pointer bg-black' onClick={closeBankList}/>
                  </div>
                </div> */}

                {/* body */}
                <div className='grid mt-2 max-h-80 overflow-y-scroll'>
                  <div className='grid px-3 py-4 rounded-sm hover:bg-slate-100 cursor-pointer'>
                    <span className='font-medium'>Oluwatobi Amusa</span>
                    <span className='text-xs'>Zenith Bank</span>
                  </div>

                  <div className='grid px-3 py-4 rounded-sm hover:bg-slate-100 cursor-pointer'>
                    <span className='font-medium'>Oluwatobi Amusa</span>
                    <span className='text-xs'>Zenith Bank</span>
                  </div>

                  <div className='grid px-3 py-4 rounded-sm hover:bg-slate-100 cursor-pointer'>
                    <span className='font-medium'>Oluwatobi Amusa</span>
                    <span className='text-xs'>Zenith Bank</span>
                  </div>

                  <div className='grid px-3 py-4 rounded-sm hover:bg-slate-100 cursor-pointer'>
                    <span className='font-medium'>Oluwatobi Amusa</span>
                    <span className='text-xs'>Zenith Bank</span>
                  </div>

                  <div className='grid px-3 py-4 rounded-sm hover:bg-slate-100 cursor-pointer'>
                    <span className='font-medium'>Oluwatobi Amusa</span>
                    <span className='text-xs'>Zenith Bank</span>
                  </div>

                  <div className='grid px-3 py-4 rounded-sm hover:bg-slate-100 cursor-pointer'>
                    <span className='font-medium'>Oluwatobi Amusa</span>
                    <span className='text-xs'>Zenith Bank</span>
                  </div>

                  <div className='grid px-3 py-4 rounded-sm hover:bg-slate-100 cursor-pointer'>
                    <span className='font-medium'>Oluwatobi Amusa</span>
                    <span className='text-xs'>Zenith Bank</span>
                  </div>
                </div>
              
              </div>
          </Box>
        </Modal>
      </div>
    );
  }
  
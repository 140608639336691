import { AmountFormatter } from "../../utils/constant";
import Show from "../../images/show.svg";
import Hide from "../../images/hide.svg";
import Add from "../../images/add.svg";
import Qr from "../../images/qr.svg";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fetchUserBalance } from "../../data/local/reducers/user.reducer";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

function Card() {
  const [hide, setHide] = useState(true);
  const handleClick = () => setHide(!hide);
  const dispatch = useDispatch();
  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const userBalance = userBalanceProfile?.amount.toLocaleString() || "0.00";
  const phoneNumber = loginProfile.user_detail[0].phonenumber;
  const providus = loginProfile.user_detail[0].account_no;
  const qrBalance =
    loginProfile.qr_customer_wallet_balance.amount?.toLocaleString() || "0.00";
  const [qrbal, setQRbal] = useState(true);
  const [balance, setBalance] = useState("");
  const changeBalance = () => setQRbal(!qrbal);

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(
        fetchUserBalance({
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token: loginProfile.token,
        })
      );

      setBalance(payload.amount);
    };
    fetchData();
  }, [dispatch, loginProfile.token, phoneNumber]);

  return (
    <div>
      <div className="rounded-t-[10px] bg-button-color grid grid-cols-3 overflow-auto">
        <div className="p-6 col-span-2 text-white gap-6 grid">
          <p className="grid">
            <span className="opacity-50 text-xs">
              Wallet number (Zippyworld)
            </span>
            <span className=" tracking-wider">{phoneNumber}</span>
          </p>
          <p className="grid">
            <span className="opacity-50 text-xs">
              Virtual account (Providus bank)
            </span>
            <span className=" tracking-wider">{providus}</span>
          </p>
        </div>
        <div className="modalHeaderBg"></div>
      </div>
      <div className="px-6 py-4 rounded-b-[10px] bg-[#1A3140] text-white">
        <div className="flex justify-between items-center">
          {qrbal ? (
            <p className="grid">
              <span className="opacity-50 text-xs">Wallet balance:</span>
              <span className=" font-medium">
                {hide ? (
                  " ******"
                ) : (
                  <AmountFormatter amount={balance ?? ""} currency="NGN" />
                )}
              </span>
            </p>
          ) : (
            <p className="grid">
              <span className="opacity-50 text-xs">Qr Code balance:</span>
              <span className=" font-medium">
                {hide ? (
                  " ******"
                ) : (
                  <AmountFormatter amount={qrBalance} currency="NGN" />
                )}
              </span>
            </p>
          )}
          <div className="flex gap-3">
            <LazyLoadImage
              src={Qr}
              alt=""
              onClick={changeBalance}
              className=" cursor-pointer"
            />
            {hide === true ? (
              <LazyLoadImage
                src={Show}
                alt=""
                onClick={handleClick}
                className=" cursor-pointer"
              />
            ) : (
              <LazyLoadImage
                src={Hide}
                alt=""
                onClick={handleClick}
                className=" cursor-pointer"
              />
            )}
            <Link to="/fundWallet">
              <LazyLoadImage src={Add} alt="" className="cursor-pointer" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;

import React, { useState, useEffect } from "react";
import NavCard from "../../Components/navCard";
import RecentTransactions from "../../Components/recentTransactions";
import StoreAd from "../../Components/storeAdvert";
import Back from "../../Components/Back";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getEstateList,
  validateEstateMembership,
} from "../../data/local/reducers/miscellaneous.reducer";
import Loading from "../../Components/SelectValue/Loading";

function PrivateEstate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [estateList, setEstateList] = useState([]);
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const phoneNumber = data.phonenumber;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { payload } = await dispatch(getEstateList());
      setEstateList(payload);
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const handleEstateValidation = async (estate_code) => {
    const { payload } = await dispatch(
      validateEstateMembership({
        estate_code: estate_code,
        phonenumber: phoneNumber,
      })
    );
    return payload.status_code;
  };

  const handleEstateClick = async (estate) => {
    const isValid = await handleEstateValidation(estate.estate_code);
    setEstateList((prevState) =>
      prevState.map((item) =>
        item.estate_code === estate.estate_code
          ? { ...item, isValid: isValid }
          : item
      )
    );

    if (isValid === "0") {
      navigate(`/estateOption?data=${encodeURIComponent(estate.estate_code)}`); // Navigate to '/otherScreen' if isValid is '0'
    }
  };

  return (
    <div>
      <div className="flex items-center text-sm font-bold gap-4 border-b pb-4">
        <Back />
        Private Estates
      </div>
      <Loading open={isLoading} />
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
            {estateList.map((estate) => (
              <div key={estate.estate_code}>
                <div onClick={() => handleEstateClick(estate)}>
                  <NavCard
                    serviceImg={estate.image}
                    serviceName={estate.name}
                  />
                </div>
              </div>
            ))}
          </div>
          <StoreAd />
        </div>
        <div>
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default PrivateEstate;

import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import SecurityAnswerModal from "../SelectValue/securityAnswerModal";
import Mobile from "../../images/9mobile.svg";
import Airtel from "../../images/Airtel.svg";
import Glo from "../../images/Glo.svg";
import Mtn from "../../images/MTN.svg";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import { showSuccessToast } from "../../utils/api-utils";
import { networkOperators } from "../../data/local/reducers/miscellaneous.reducer";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";
import { dataPurchase } from "../../data/local/reducers/miscellaneous.reducer";
import { LazyLoadImage } from "react-lazy-load-image-component";

function DataForm() {
  const [value, setValue] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const phoneNumber = data.phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);
  const [networkCode, setSelectedNetworkCode] = useState(null);
  const handleClose = () => setValue(false);

  const dataPlan = users.miscellaneous;

  const initialValues = {
    network_code: "",
    beneficiary_phonenumber: "",
    plan_code: "",
  };

  const handleNetworkChange = (event) => {
    const networkCodes = event.target.value;
    setSelectedNetworkCode(networkCodes);
    const serviceCode = "ZWSC02";
    const { payload } = dispatch(
      networkOperators({ service_code: serviceCode, networkCodes })
    );
  };

  const validationSchema = Yup.object({
    beneficiary_phonenumber: Yup.string().required("Required"),
    plan_code: Yup.string().required("Required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    const dataPlans = values.plan_code;
    const planCodes = dataPlans.split("|")[0];
    const amountGLO = dataPlans.split('|');
    const finalamountGlo = amountGLO[1]; 
    const numberPart = finalamountGlo.split(' ')[0]; 
    const { beneficiary_phonenumber, amount = numberPart } = values;
    const datass = {
      plan_code: planCodes,
      beneficiary_phonenumber,
      network_code: networkCode,
      amount,
    };
    setFormValues(datass); // Store the form values in the state
    setValue(true);
    resetForm();
  };
  const onSubmit = async (values, { resetForm }) => {
    setFormValues(values); 
    setValue(true);
    const formData = {
      ...formValues,
      security_answer: values.securityanswer,
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
      operation_summary: "data_purchase",
      service_code: "ZWSC02",
      phonenumber: data.phonenumber,
    };
    handleClose();
    const { payload } = await dispatch(dataPurchase(formData));
    dispatch(
      fetchUserBalance({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    dispatch(
      fetchTransactionList({
        phonenumber: phoneNumber,
        transaction_id: generateTransactionId(),
        channel: "web",
        token: token,
      })
    );
    if (payload.status_code === "0") {
      resetForm();
      showSuccessToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />
      <SecurityAnswerModal
        open={value}
        handleClose={handleClose}
        onSubmit={onSubmit}
      />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, touched, event }) => (
          <Form>

            <p className="px-4 text-sm mb-2">Select Network Operator:</p>
            <div className="px-4 pb-4 grid grid-cols-2 md:grid-cols-3">
            
              <label
                className={`p-1 cursor-pointer ${values.network_code === "ZWNO04" ? "border border-green-500" : "" }`}

              >
                <Field
                  type="radio"
                  name="network_code"
                  value="ZWNO04"
                  
                  className="hidden"
                  onChange={handleNetworkChange}
                />
                <LazyLoadImage
                  src={Mobile}
                  className="w-full h-full rounded-md"
                  alt="9mobile"
                />
              </label>
              <label
                className={`p-1 cursor-pointer ${
                  values.network_code === "ZWNO01"
                    ? "border border-[#E20010]"
                    : ""
                }`}
              >
                <Field
                  type="radio"
                  name="network_code"
                  value="ZWNO01"
                  className="hidden"
                  onChange={handleNetworkChange}
                />
                <LazyLoadImage
                  src={Airtel}
                  className="w-full h-full rounded-md"
                  alt="Airtel"
                />
              </label>
              <label
                className={`p-1 cursor-pointer ${
                  values.network_code === "ZWNO02"
                    ? "border border-[#93DF7E]"
                    : ""
                }`}
              >
                <Field
                  type="radio"
                  name="network_code"
                  value="ZWNO02"
                  className="hidden"
                  onChange={handleNetworkChange}
                />
                <LazyLoadImage src={Glo} className="w-full h-full rounded-md" alt="Glo" />
              </label>
              <label
                className={`p-1 cursor-pointer ${
                  values.network_code === "ZWNO03"
                    ? "border border-[#FBCA70]"
                    : ""
                }`}
              >
                <Field
                  type="radio"
                  name="network_code"
                  value="ZWNO03"
                  className="hidden"
                  onChange={handleNetworkChange}
                />
                <LazyLoadImage src={Mtn} className="w-full h-full rounded-md" alt="MTN" />
              </label>
            </div>

            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={values.beneficiary_phonenumber}
                type="text"
                label="Phone Number:"
                name="beneficiary_phonenumber"
              />

              <FormikControl
                control="selectData"
                value={values.data_plan}
                options={dataPlan ?? []}
                type="integer"
                label="Data Plan:"
                name="plan_code"
              />
            </div>


            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default DataForm;

import React from 'react'
import Back from '../../Components/Back'
import { Link } from 'react-router-dom'
import NavCard from '../../Components/navCard'
import bulkAirtimeImage from '../../images/bulk_airtime.png';
import bulkDataImage from '../../images/bulk_data.png';
import bulkTransferImage from '../../images/bank transfer img.svg'
import StoreAd from '../../Components/storeAdvert';
import RecentTransactions from '../../Components/recentTransactions';

function BulkOperations() {
  return (
    <div>
      <div className='flex items-center text-sm font-bold gap-4 border-b pb-4'>
        <Back />
        Bulk Airtime and Data.
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5'>
         <div className='lg:col-span-2'>
           <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mb-5'>
             <Link to='/bulkAirtime'>
               <NavCard  serviceImg = {bulkAirtimeImage} serviceName = "Buy Bulk Airtime" />
             </Link>
             <Link to='/bulkData'>
             <NavCard  serviceImg = {bulkDataImage} serviceName = "Buy Bulk Data" />
             </Link>
             <Link to='/bulkTransfer'>
             <NavCard  serviceImg = {bulkTransferImage} serviceName = "Bulk Transfer" />
             </Link>
           </div> 
           <StoreAd />
         </div>
         <div>
          <RecentTransactions />
         </div>
      </div>
    </div>
  )
}

export default BulkOperations
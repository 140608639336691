import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { APIService } from "../../remote/services/api.services";
import { retrieveFromLocalStorage } from "../../../utils/constant";
import TA_Garden_Image from "../../../images/TA_Garden.jpg";
import estate2 from "../../../images/estate3.png";
import estate3 from "../../../images/estate3.png";
import estate4 from "../../../images/estate4.png";
import estate5 from "../../../images/estate5.png";
import { showErrorToast } from "../../../utils/api-utils";

const initialState = {
  miscellaneous: null,
  loading: false,
  error: null,
  ...retrieveFromLocalStorage(["estateMeterValidationProfile", "fetchJambTransactionProfile"]),
};

const saveToLocalStorage = (key, data) => {
  sessionStorage.setItem(key, data);
  // data;
};

export const networkOperators = createAsyncThunk(
  "misc/networkOperators",
  async (data) => {
    const networkOperator = await APIService.fetchDataPlans(data);
    const response = await networkOperator.data["result"].dataPlan;
    return response;
  }
);

export const getBanks = createAsyncThunk("misc/getBanks", async () => {
  const getBanks = await APIService.get_banks();
  const response = await getBanks.data["result"];
  return response;
});

export const getEstateList = createAsyncThunk(
  "misc/getEstateList",
  async () => {
    const getEstateList = await APIService.fetchPrivateEstateList();
    const estateList = await getEstateList.data;
    const response = estateList.result.map((estate) => ({
      ...estate,
      image: getImageForEstate(estate.name), // Use the function to get the image based on the estate name
    }));

    return response;
  }
);

const getImageForEstate = (estateName) => {
  switch (estateName) {
    case "TA_Garden":
      return TA_Garden_Image;
    case "Omole Estate":
      return estate2;
    case "Magodo Estate":
      return estate3;
    case "Goosepen Estate":
      return estate4;
    case "Glover Court":
      return estate5;
    default:
      return TA_Garden_Image;
  }
};

export const airtimePurchase = createAsyncThunk(
  "misc/airtimePurchase",
  async (airtimePurchaseCredentials) => {
    const purchaseAirtime = await APIService.airtime_purchase(
      airtimePurchaseCredentials
    );
    const response = await purchaseAirtime.data;
    return response;
  }
);

export const dataPurchase = createAsyncThunk(
  "misc/dataPurchase",
  async (dataPurchaseCredentials) => {
    const purchaseData = await APIService.data_purchase(
      dataPurchaseCredentials
    );
    const response = await purchaseData.data;
    return response;
  }
);

export const userDetails = createAsyncThunk(
  "misc/userDetails",
  async (userPurchaseCredentials) => {
    const userData = await APIService.user_details(userPurchaseCredentials);
    const response = await userData.data.user_detail["0"];
    return response;
  }
);

export const walletTransfer = createAsyncThunk(
  "misc/walletTransfer",
  async (walletTransferCredentials) => {
    const walletTransferData = await APIService.wallet_transfer(
      walletTransferCredentials
    );
    const response = await walletTransferData.data;
    return response;
  }
);

export const bankTransfer = createAsyncThunk(
  "misc/bankTransfer",
  async (bankTransferCredentials) => {
    const bankTransferData = await APIService.bank_transfer(
      bankTransferCredentials
    );
    const response = await bankTransferData.data;
    return response;
  }
);

export const rrrLookup = createAsyncThunk(
  "misc/rrrLookup",
  async (rrrNumber) => {
    const rrrLookupDAta = await APIService.rrr_lookup(
      rrrNumber
    );
    const response = await rrrLookupDAta.data;
    return response;
  }
);

export const processRemitaPayment = createAsyncThunk(
  "misc/processRemitaPayment",
  async (rrrNumber) => {
    const processRemitaPaymentDAta = await APIService.processRemitaPayment(
      rrrNumber
    );
    const response = await processRemitaPaymentDAta.data;
    return response;
  }
);

export const getBillerCategory = createAsyncThunk(
  "misc/getBillerCategory",
  async () => {
    const getBillerCategoryData = await APIService.getBillerCategory();
    const response = await getBillerCategoryData.data;
    return response;
  }
);

export const getBillerCategoryById = createAsyncThunk(
  "misc/getBillerCategoryById",
  async (categoryId) => {
    const getBillerCategoryByIdData = await APIService.getBillerCategoryById(categoryId);
    const response = await getBillerCategoryByIdData.data;
    return response;
  }
);

export const getBillerProductByBillerId = createAsyncThunk(
  "misc/getBillerProductByBillerId",
  async (billerId) => {
    const getBillerProductByBillerIdData = await APIService.getBillerProductByBillerId(billerId);
    const response = await getBillerProductByBillerIdData.data;
    return response;
  }
);

export const initiateRemitaPayments = createAsyncThunk(
  "misc/initiateRemitaPayments",
  async (remitaParameters) => {
    const initiateRemitaPaymentData = await APIService.initiateRemitaPayment(remitaParameters);
    const response = await initiateRemitaPaymentData.data;
    return response;
  }
);


export const validateBillerNumber = createAsyncThunk(
  "misc/validateBillerNumber",
  async (billerParameters) => {
    const validateBillerNumberData = await APIService.validateBillerId(billerParameters);
    const response = await validateBillerNumberData.data;
    return response;
  }
);

export const searchBillers = createAsyncThunk(
  "misc/searchBillers",
  async (billerParameters) => {
    const searchBillersData = await APIService.searchBillers(billerParameters);
    const response = await searchBillersData.data;
    return response;
  }
);



export const resultPinPayment = createAsyncThunk(
  "misc/resultPinPayment",
  async (validationParameters) => {
    const resultPinPaymentData = await APIService.resultPin(
      validationParameters
    );
    const response = await resultPinPaymentData.data;
    return response;
  }
);

export const registrationToken = createAsyncThunk(
  "misc/registrationToken",
  async (validationParameters) => {
    const registrationTokenData = await APIService.registrationToken(
      validationParameters
    );
    const response = await registrationTokenData.data;
    return response;
  }
);


export const validateEstateMembership = createAsyncThunk(
  "misc/validateEstateMembership",
  async (validateEstateMembershipCredentials) => {
    const validateEstateMembershipData =
      await APIService.validateEstateMembership(
        validateEstateMembershipCredentials
      );
    const response = await validateEstateMembershipData.data;
    return response;
  }
);

export const estateElectricity = createAsyncThunk(
  "misc/estateElectricity",
  async (estateElectricityCredentials) => {
    const estateElectricityData = await APIService.buyEstateElectricity(
      estateElectricityCredentials
    );
    const response = await estateElectricityData.data;
    return response;
  }
);
//generatePassToken
export const generatePass = createAsyncThunk(
  "misc/generatePass",
  async (generatePassCredentials) => {
    const generatePassData = await APIService.generatePassToken(
      generatePassCredentials
    );
    const response = await generatePassData.data;
    return response;
  }
);

//estatePayment
export const estatePayment = createAsyncThunk(
  "misc/estatePayment",
  async (estatePaymentCredentials) => {
    const generatePassData = await APIService.estateProductPayment(
      estatePaymentCredentials
    );
    const response = await generatePassData.data;
    return response;
  }
);

//generatePassToken
export const estatesHistory = createAsyncThunk(
  "misc/estatesHistory",
  async (estateCode) => {
    const estateHistoryData = await APIService.estateHistory(estateCode);
    const response = await estateHistoryData.data;
    return response;
  }
);
//validate pass
export const validatePass = createAsyncThunk(
  "misc/validatePass",
  async (validatePassCredentials) => {
    const validatePassData = await APIService.validatePassToken(
      validatePassCredentials
    );
    const response = await validatePassData.data;
    return response;
  }
);

//estateFibreProduct
export const fibreProduct = createAsyncThunk(
  "misc/fibreProduct",
  async (validateFibreCredentials) => {
    const validateFibreProduct = await APIService.estateFibreProduct(
      validateFibreCredentials
    );
    const response = await validateFibreProduct.data;
    return response;
  }
);

export const accountVerification = createAsyncThunk(
  "misc/accountVerify",
  async (accountVerifyCredentials) => {
    const accountVerifyData = await APIService.account_number(
      accountVerifyCredentials
    );
    const response = await accountVerifyData.data;
    return response;
  }
);

export const electricOperators = createAsyncThunk(
  "misc/electricOperators",
  async (provider) => {
    const electricOperatorsData = await APIService.electric_operators(provider);
    const response = await electricOperatorsData.data;
    return response;
  }
);

export const estateMeterValidation = createAsyncThunk(
  "misc/meterValidation",
  async (meterNumber) => {
    const meterValidationData = await APIService.estateMeterNumberValidation(
      meterNumber
    );
    const response = await meterValidationData.data;
    saveToLocalStorage(
      "estateMeterValidationProfile",
      JSON.stringify(response)
    ); // Save the session in local storage
    return response;
  }
);

export const electricValidation = createAsyncThunk(
  "misc/electricValidation",
  async (provider) => {
    const electricValidationData = await APIService.validate_electricity(
      provider
    );
    const response = await electricValidationData.data;
    return response;
  }
);

export const electricitySubscription = createAsyncThunk(
  "misc/electricSubscription",
  async (provider) => {
    const electricSubscriptionData = await APIService.subscribe_electricity(
      provider
    );
    const response = await electricSubscriptionData.data;
    return response;
  }
);
export const fundWallet = createAsyncThunk(
  "misc/fundWallet",
  async (provider) => {
    const fundWalletData = await APIService.fundWallet(provider);
    const response = await fundWalletData.data;
    return response;
  }
);
export const topUpWallet = createAsyncThunk(
  "misc/topUpWallet",
  async (provider) => {
    const topUpWalletData = await APIService.topUpWallet(provider);
    const response = await topUpWalletData.data;
    return response;
  }
);
//shagoCardValidation
export const shagoCardValidation = createAsyncThunk(
  "misc/shagoCardValidation",
  async (providerCredentials) => {
    const shagoCardValidationData = await APIService.shagoCardValidation(
      providerCredentials
    );
    const response = await shagoCardValidationData.data;
    return response;
  }
);

export const cardValidation = createAsyncThunk(
  "misc/cardValidation",
  async (providerCredentials) => {
    const cardValidationData = await APIService.cardValidation(
      providerCredentials
    );
    const response = await cardValidationData.data;
    return response;
  }
);

export const shagoCableTvAddons = createAsyncThunk(
  "misc/shagoCableTvAddons",
  async (providerCredentials) => {
    const shagoCableTvAddonsData = await APIService.fetchShagoCableTVAddons(
      providerCredentials
    );
    const response = await shagoCableTvAddonsData.data;
    return response;
  }
);

export const shagoCableTvSubscription = createAsyncThunk(
  "misc/shagoCableTvSubscription",
  async (providerCredentials) => {
    const shagoCableTvSubscriptionData = await APIService.subscribeShagoCableTv(
      providerCredentials
    );
    const response = await shagoCableTvSubscriptionData.data;
    return response;
  }
);

export const cableTvSubscription = createAsyncThunk(
  "misc/cableTvSubscription",
  async (providerCredentials) => {
    const cableTvSubscriptionData = await APIService.subscribeCableTv(
      providerCredentials
    );
    const response = await cableTvSubscriptionData.data;
    return response;
  }
);

// shagoTopUpCableTvSubscription
export const shagoTopUpCableTvSubscription = createAsyncThunk(
  "misc/shagoTopUpCableTvSubscription",
  async (providerCredentials) => {
    const shagoTopUpCableTvSubscriptionData =
      await APIService.subscribeTopUpShagoCableTv(providerCredentials);
    const response = await shagoTopUpCableTvSubscriptionData.data;
    return response;
  }
);

//cableTvProduct
export const cableTvProduct = createAsyncThunk(
  "misc/cableTvProduct",
  async (providerCredentials) => {
    const cableTvProductData = await APIService.getCableProducts(
      providerCredentials
    );
    const response = await cableTvProductData.data;
    return response;
  }
);

//cableTvAddon
export const cableTvAddon = createAsyncThunk(
  "misc/cableTvAddon",
  async (providerCredentials) => {
    const cableTvAddonData = await APIService.getCableAddons(
      providerCredentials
    );
    const response = await cableTvAddonData.data;
    return response;
  }
);
//saveWalletBeneficiaries
export const saveWalletBeneficiaries = createAsyncThunk(
  "misc/saveWalletBeneficiaries",
  async (providerCredentials) => {
    const saveWalletBeneficiariesData =
      await APIService.saveWalletBeneficiaries(providerCredentials);
    const response = await saveWalletBeneficiariesData.data;
    return response;
  }
);

//wallet beneficiaries
export const walletBeneficiary = createAsyncThunk(
  "misc/walletBeneficiary",
  async (providerCredentials) => {
    const walletBeneficiaryData = await APIService.walletBeneficiaries(
      providerCredentials
    );
    const response = await walletBeneficiaryData.data;
    return response;
  }
);

//save bank beneficiaries
export const saveBankBeneficiaries = createAsyncThunk(
  "misc/saveBankBeneficiaries",
  async (providerCredentials) => {
    const saveBankBeneficiariesData = await APIService.saveBankBeneficiaries(
      providerCredentials
    );
    const response = await saveBankBeneficiariesData.data;
    return response;
  }
);

//get bank beneficiaries
export const bankBeneficiary = createAsyncThunk(
  "misc/bankBeneficiary",
  async (providerCredentials) => {
    const bankBeneficiaryData = await APIService.bankBeneficiaries(
      providerCredentials
    );
    const response = await bankBeneficiaryData.data;
    return response;
  }
);

//save electricity Beneficiaries
export const saveElectricityBeneficiaries = createAsyncThunk(
  "misc/saveElectricityBeneficiaries",
  async (providerCredentials) => {
    const saveElectricityBeneficiariesData =
      await APIService.saveElectricityBeneficiaries(providerCredentials);
    const response = await saveElectricityBeneficiariesData.data;
    return response;
  }
);

//get electricity beneficiaries
export const electricityBeneficiary = createAsyncThunk(
  "misc/electricityBeneficiary",
  async (providerCredentials) => {
    const electricityBeneficiaryData =
      await APIService.electricityBeneficiaries(providerCredentials);
    const response = await electricityBeneficiaryData.data;
    return response;
  }
);

//save cable Beneficiaries
export const saveCableBeneficiaries = createAsyncThunk(
  "misc/saveCableBeneficiaries",
  async (providerCredentials) => {
    const saveCableBeneficiariesData = await APIService.saveCableBeneficiaries(
      providerCredentials
    );
    const response = await saveCableBeneficiariesData.data;
    return response;
  }
);

//get cable beneficiaries
export const cableBeneficiary = createAsyncThunk(
  "misc/cableBeneficiary",
  async (providerCredentials) => {
    const cableBeneficiaryData = await APIService.cableBeneficiaries(
      providerCredentials
    );
    const response = await cableBeneficiaryData.data;
    return response;
  }
);

//invoice Profiling
export const invoiceProfiling = createAsyncThunk(
  "misc/invoiceProfiling",
  async (providerCredentials) => {
    const invoiceProfilingData = await APIService.invoiceProfiling(
      providerCredentials
    );
    const response = await invoiceProfilingData.data;
    return response;
  }
);

//save product
export const saveProduct = createAsyncThunk(
  "misc/saveProduct",
  async (saveProductCredentials) => {
    const saveProductData = await APIService.addProduct(saveProductCredentials);
    const response = await saveProductData.data;
    return response;
  }
);

//invoice profiling details
export const myDetails = createAsyncThunk(
  "misc/myDetails",
  async (myDetailsCredentials) => {
    const myDetailsData = await APIService.myProfile(myDetailsCredentials);
    const response = await myDetailsData.data;
    return response;
  }
);

//identity Upload
export const identityUpload = createAsyncThunk(
  "misc/identityUpload",
  async (identityUploadCredentials) => {
    const identityUploadData = await APIService.uploadLogo(
      identityUploadCredentials
    );
    const response = await identityUploadData.data;
    return response;
  }
);

//valid id upload
export const validIdUpload = createAsyncThunk(
  "misc/validIdUpload",
  async (validIdUploadCredentials) => {
    const validIdUploadData = await APIService.uploadLogo(
      validIdUploadCredentials
    );
    const response = await validIdUploadData.data;
    return response;
  }
);

//add new customer
export const addNewCustomer = createAsyncThunk(
  "misc/addNewCustomer",
  async (addNewCustomerCredentials) => {
    const addNewCustomerData = await APIService.addNewCustomer(
      addNewCustomerCredentials
    );
    const response = await addNewCustomerData.data;
    return response;
  }
);

//customer list
export const customerList = createAsyncThunk(
  "misc/customerList",
  async (customerListCredentials) => {
    const customerListData = await APIService.customerList(
      customerListCredentials
    );
    const response = await customerListData.data;
    return response;
  }
);

//referral list
export const referralList = createAsyncThunk(
  "misc/referralList",
  async (referralListCredentials) => {
    const referralListData = await APIService.referral(referralListCredentials);
    const response = await referralListData.data;
    return response;
  }
);

//add items
export const addItems = createAsyncThunk(
  "misc/addItems",
  async (addItemsCredentials) => {
    const addItemsData = await APIService.addItems(addItemsCredentials);
    const response = await addItemsData.data;
    return response;
  }
);

//invoice History
export const invoiceHistory = createAsyncThunk(
  "misc/invoiceHistory",
  async (invoiceHistoryCredentials) => {
    const invoiceHistoryData = await APIService.invoiceHistory(
      invoiceHistoryCredentials
    );
    const response = await invoiceHistoryData.data;
    return response;
  }
);

//jamb History
export const waecHistory = createAsyncThunk(
  "misc/waecHistory",
  async (waecHistoryCredentials) => {
    const waecHistoryData = await APIService.waecTransactions(
      waecHistoryCredentials
    );
    const response = await waecHistoryData.data;
    return response;
  }
);

//invoice List
export const invoiceList = createAsyncThunk(
  "misc/invoiceList",
  async (invoiceListCredentials) => {
    const invoiceListData = await APIService.invoiceList(
      invoiceListCredentials
    );
    const response = await invoiceListData.data;
    return response;
  }
);

//jamb vend
export const jambVend = createAsyncThunk(
  "misc/jambVend",
  async (jambVendCredentials) => {
    const jambVendData = await APIService.jambRegistration(jambVendCredentials);
    const response = await jambVendData.data;
    return response;
  }
);
//jamb profiling
export const jambProfiling = createAsyncThunk(
  "misc/jambProfiling",
  async (jambProfilingCredentials) => {
    const jambProfilingData = await APIService.jambProfiling(jambProfilingCredentials);
    const response = await jambProfilingData.data;
    return response;
  }
);
//jamb retrieve
export const jambSwap = createAsyncThunk(
  "misc/jambSwap",
  async (jambSwapCredentials) => {
    const jambSwapData = await APIService.jambRetrieve(jambSwapCredentials);
    const response = await jambSwapData.data;
    return response;
  }
);

//examination type
export const jambType = createAsyncThunk(
  "misc/jambType",
  async (jambTypeCredentials) => {
    const jambTypeData = await APIService.getJambType(jambTypeCredentials);
    const response = await jambTypeData.data;
    return response;
  }
);

//jamb transaction list

export const fetchJambTransactionList = createAsyncThunk(
  "misc/fetchJambTransactionList",
  async (phoneNumber) => {
    const fetchJambTransactionList = await APIService.fetchJambTransactionList(
      phoneNumber
    );
    const response = await fetchJambTransactionList.data;
    saveToLocalStorage("fetchJambTransactionProfile", JSON.stringify(response));
    return response;
  }
);

const miscellaneousSlice = createSlice({
  name: "misc",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(airtimePurchase.pending, (state) => {
        state.loading = true;
        state.miscellaneous = null;
        state.error = null;
      })
      .addCase(airtimePurchase.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(airtimePurchase.rejected, (state, action) => {
        state.loading = false;
        state.miscellaneous = null;
        state.error = showErrorToast(action.error.message);
      })
      .addCase(networkOperators.pending, (state) => {
        state.loading = true;
        state.miscellaneous = null;
        state.error = null;
      })
      .addCase(networkOperators.fulfilled, (state, action) => {
        state.miscellaneous = action.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(networkOperators.rejected, (state, action) => {
        state.loading = false;
        state.miscellaneous = null;
        state.error = showErrorToast(action.error.message);
      })
      .addCase(fundWallet.pending, (state) => {
        state.loading = true;
        state.miscellaneous = null;
        state.error = null;
      })
      .addCase(fundWallet.fulfilled, (state, action) => {
        state.miscellaneous = action.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(fundWallet.rejected, (state, action) => {
        state.loading = false;
        state.miscellaneous = null;
        state.error = showErrorToast(action.error.message);
      })
      .addCase(dataPurchase.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = null;
        }
        state.loading = false;
      })

      .addCase(userDetails.pending, (state) => {
        state.loading = true;
        state.miscellaneous = null;
        state.error = null;
      })
      .addCase(userDetails.fulfilled, (state, action) => {
        state.miscellaneous = action.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(userDetails.rejected, (state, action) => {
        state.loading = false;
        state.miscellaneous = null;
        state.error = showErrorToast(action.error.message);
      })
      .addCase(electricOperators.pending, (state) => {
        state.loading = true;
        state.miscellaneous = null;
        state.error = null;
      })
      .addCase(electricOperators.fulfilled, (state, action) => {
        state.miscellaneous = action.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(electricOperators.rejected, (state, action) => {
        state.loading = false;
        state.miscellaneous = null;
        state.error = showErrorToast(action.error.message);
      })
      .addCase(getEstateList.pending, (state) => {
        state.loading = true;
        state.miscellaneous = null;
        state.error = null;
      })
      .addCase(getEstateList.fulfilled, (state, action) => {
        state.miscellaneous = action.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(getEstateList.rejected, (state, action) => {
        state.loading = false;
        state.miscellaneous = null;
        state.error = showErrorToast(action.error.message);
      })
      .addCase(walletTransfer.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(estateElectricity.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(bankTransfer.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(accountVerification.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(electricValidation.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(estateMeterValidation.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
          state.estateMeterValidationProfile = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          // showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(generatePass.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(validatePass.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(estatesHistory.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(fibreProduct.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(estatePayment.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(electricitySubscription.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(topUpWallet.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(shagoCardValidation.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(cardValidation.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(shagoCableTvAddons.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(shagoCableTvSubscription.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(cableTvSubscription.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(shagoTopUpCableTvSubscription.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(cableTvProduct.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(cableTvAddon.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(walletBeneficiary.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          //showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(saveWalletBeneficiaries.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(saveBankBeneficiaries.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(bankBeneficiary.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(electricityBeneficiary.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(saveElectricityBeneficiaries.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(cableBeneficiary.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(saveCableBeneficiaries.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(invoiceProfiling.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(saveProduct.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(myDetails.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(identityUpload.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(validIdUpload.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(addNewCustomer.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(customerList.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(addItems.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(invoiceHistory.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(invoiceList.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(validateEstateMembership.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(referralList.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(jambProfiling.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(jambSwap.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(jambVend.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(jambType.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(fetchJambTransactionList.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
          state.fetchJambTransactionProfile = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
          //showErrorToast(action.payload.message);
        } else {
          state.error = action.payload.message;
          //showErrorToast(action.payload.message);
        }
        state.loading = false;
      })
      .addCase(rrrLookup.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
        } else {
          state.error = action.payload.message;
        }
        state.loading = false;
      })
      .addCase(processRemitaPayment.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
        } else {
          state.error = action.payload.message;
        }
        state.loading = false;
      })
      .addCase(resultPinPayment.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
        } else {
          state.error = action.payload.message;
        }
        state.loading = false;
      })
      .addCase(getBillerCategory.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
        } else {
          state.error = action.payload.message;
        }
        state.loading = false;
      })
      .addCase(getBillerCategoryById.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
        } else {
          state.error = action.payload.message;
        }
        state.loading = false;
      })
      .addCase(getBillerProductByBillerId.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
        } else {
          state.error = action.payload.message;
        }
        state.loading = false;
      })
      .addCase(registrationToken.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
        } else {
          state.error = action.payload.message;
        }
        state.loading = false;
      })
      .addCase(initiateRemitaPayments.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
        } else {
          state.error = action.payload.message;
        }
        state.loading = false;
      })
      .addCase(validateBillerNumber.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
        } else {
          state.error = action.payload.message;
        }
        state.loading = false;
      })
      .addCase(searchBillers.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
        } else {
          state.error = action.payload.message;
        }
        state.loading = false;
      })
      .addCase(waecHistory.fulfilled, (state, action) => {
        if (action.payload.status_code === "0") {
          state.miscellaneous = action.payload;
        } else if (action.payload.status_code === "1") {
          state.error = action.payload.message;
        } else {
          state.error = action.payload.message;
        }
        state.loading = false;
      })
      .addMatcher(
        isAnyOf(
          dataPurchase.pending,
          walletTransfer.pending,
          bankTransfer.pending,
          accountVerification.pending,
          electricValidation.pending,
          estateElectricity.pending,
          estateMeterValidation.pending,
          generatePass.pending,
          validatePass.pending,
          estatesHistory.pending,
          fibreProduct.pending,
          estatePayment.pending,
          electricitySubscription.pending,
          topUpWallet.pending,
          shagoCardValidation.pending,
          cardValidation.pending,
          shagoCableTvAddons.pending,
          shagoCableTvSubscription.pending,
          cableTvSubscription.pending,
          shagoTopUpCableTvSubscription.pending,
          cableTvProduct.pending,
          cableTvAddon.pending,
          walletBeneficiary.pending,
          saveWalletBeneficiaries.pending,
          saveBankBeneficiaries.pending,
          bankBeneficiary.pending,
          saveElectricityBeneficiaries.pending,
          electricityBeneficiary.pending,
          saveCableBeneficiaries.pending,
          cableBeneficiary.pending,
          invoiceProfiling.pending,
          saveProduct.pending,
          myDetails.pending,
          identityUpload.pending,
          validIdUpload.pending,
          addNewCustomer.pending,
          customerList.pending,
          addItems.pending,
          invoiceHistory.pending,
          invoiceList.pending,
          referralList.pending,
          validateEstateMembership.pending,
          jambProfiling.pending,
          jambVend.pending,
          jambSwap.pending,
          jambType.pending,
          fetchJambTransactionList.pending,
          rrrLookup.pending,
          processRemitaPayment.pending,
          resultPinPayment.pending,
          getBillerCategory.pending,
          getBillerCategoryById.pending,
          getBillerProductByBillerId.pending,
          registrationToken.pending,
          initiateRemitaPayments.pending,
          validateBillerNumber.pending,
          searchBillers.pending,
          waecHistory.pending
        ),
        (state) => {
          state.loading = true;
          state.miscellaneous = null;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(
          dataPurchase.rejected,
          walletTransfer.rejected,
          bankTransfer.rejected,
          accountVerification.rejected,
          electricValidation.rejected,
          estateElectricity.rejected,
          estateMeterValidation.rejected,
          generatePass.rejected,
          validatePass.rejected,
          estatesHistory.rejected,
          fibreProduct.rejected,
          estatePayment.rejected,
          electricitySubscription.rejected,
          topUpWallet.rejected,
          shagoCardValidation.rejected,
          cardValidation.rejected,
          shagoCableTvAddons.rejected,
          shagoCableTvSubscription.rejected,
          cableTvSubscription.rejected,
          shagoTopUpCableTvSubscription.rejected,
          cableTvProduct.rejected,
          cableTvAddon.rejected,
          walletBeneficiary.rejected,
          saveWalletBeneficiaries.rejected,
          saveBankBeneficiaries.rejected,
          bankBeneficiary.rejected,
          saveElectricityBeneficiaries.rejected,
          electricityBeneficiary.rejected,
          saveCableBeneficiaries.rejected,
          cableBeneficiary.rejected,
          invoiceProfiling.rejected,
          saveProduct.rejected,
          myDetails.rejected,
          identityUpload.rejected,
          validIdUpload.rejected,
          addNewCustomer.rejected,
          customerList.rejected,
          addItems.rejected,
          invoiceHistory.rejected,
          invoiceList.rejected,
          referralList.rejected,
          validateEstateMembership.rejected,
          jambProfiling.rejected,
          jambSwap.rejected,
          jambVend.rejected,
          jambType.rejected,
          fetchJambTransactionList.rejected,
          rrrLookup.rejected,
          processRemitaPayment.rejected,
          resultPinPayment.rejected,
          getBillerCategory.rejected,
          getBillerCategoryById.rejected,
          getBillerProductByBillerId.rejected,
          registrationToken.rejected,
          initiateRemitaPayments.rejected,
          validateBillerNumber.rejected,
          searchBillers.rejected,
          waecHistory.rejected
        ),
        (state, action) => {
          state.loading = false;
          state.miscellaneous = null;
          state.error = showErrorToast(action.error.message);
        }
      );
  },
});

// export const { setCurrentPost } = miscellaneouslice.actions;
export const MiscellaneousReducer = miscellaneousSlice.reducer;

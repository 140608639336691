import React from "react";

function Banneroutside() {
  return (
    <div className="hidden md:block">
      <div className="h-screen grid content-center p-2">
        <div className="signin h-96 p-16 text-center text-sm text-white grid content-end">
          <div>
            <span className="bg-white/25 px-5 py-3 rounded-full text-xs">
              Photo credit: Ono Kosuki from Pexels
            </span>
            <p className="mt-5">
              Make your DSTV, GoTV and Startimes subscriptions on zippyworld to
              enjoy the 2024 AFRICA NATIONS CUP
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banneroutside;

import React from "react";
import debiticon from "../images/debit icon.svg";
import crediticon from "../images/credit icon.svg";
import { useSelector } from "react-redux";
import { AmountFormatter, FormattedDate } from "../utils/constant";

function Transactions() {
  const transactionList = useSelector(
    (state) => state.user.fetchTransactionProfile
  );
  // Access the properties of loginProfile and verifyBVNProfile objects
  const listTransaction = transactionList.result;
  const limitedTransactions = listTransaction.slice(0, 5);

  return (
    <ul className="p-4">
      <li>
        {limitedTransactions.map((object, index) => (
          <div className="grid grid-cols-6 py-3 items-stretch" key={index}>
            {object &&
            object.status &&
            object.status.toLowerCase() === "successful" ? (
              <div className="w-8 self-center">
                <img src={crediticon} alt="" />
              </div>
            ) : (
              <div className="self-center w-8">
                <img src={debiticon} alt="" />
              </div>
            )}

            <div className="col-span-3 truncate">
              <span className=" text-sm font-medium">
                {object.service_account_name}
              </span>
              <br />
              <span className="text-xs opacity-60">
                <FormattedDate dateFromTable={object.operation_at} />
              </span>
            </div>
            <div className="col-span-2 text-right text-xs self-end font-semibold">
              <AmountFormatter amount={object.amount} currency="NGN" />
            </div>
          </div>
        ))}
      </li>
    </ul>
  );
}

export default Transactions;

import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import { jambVend } from "../../data/local/reducers/miscellaneous.reducer";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import { jambReceipt, jambVendReceipt } from "../../utils/constant";
import { showErrorToast } from "../../utils/api-utils";

function BuyPinForm() {
  const dispatch = useDispatch();
  const location = useLocation();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);

  const initialValues = {
    firstname: location.state.info["firstname"],
    lastname: location.state.info["lastname"],
    middlename: location.state.info["middlename"],
    candidate_phonenumber: location.state.info["phonenumber"],
    designate_id: location.state.info["designate_id"],
    amount: location.state.amount,
    is_mock_candidate: "",
  };

  const validationSchema = Yup.object({
    //product: Yup.string().required("Required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const { candidate_phonenumber, designate_id } = values;
    const formData = {
      candidate_phonenumber: candidate_phonenumber,
      designate_id: designate_id,
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token: token,
      phonenumber: data.phonenumber,
      exam_details:
        location.state.exam_code +
        "|" +
        location.state.exam_type +
        "|" +
        location.state.amount,
        payment_option: location.state.payment_option
    };

   

    // // Print single transaction
    const handleSinglePrint = async (Content) => {
      const printWindow = window.open("", "_blank");
      printWindow.document.open();
      printWindow.document.write(Content);
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
      }, 300);
    };

    const { payload } = await dispatch(jambVend(formData));

    if (payload.status_code === "0") {
      resetForm();
      const htmlSingleContent = jambVendReceipt(payload);
      handleSinglePrint(htmlSingleContent);
    } else {
      showErrorToast(payload.message);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, handleChange, touched }) => (
          <Form>
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormikControl
                control="input"
                value={values.firstname}
                type="integer"
                label="First Name:"
                name="firstname"
                disabled={true}
              />
              <FormikControl
                control="input"
                value={values.lastname}
                type="integer"
                label="Last Name:"
                name="lastname"
                disabled={true}
              />
              <FormikControl
                control="input"
                value={values.middlename}
                type="integer"
                label="Middle Name:"
                name="middlename"
                disabled={true}
              />
              <FormikControl
                control="input"
                value={values.candidate_phonenumber}
                type="integer"
                label="Candidate Phonenumber:"
                name="candidate_phonenumber"
                disabled={true}
              />
              <FormikControl
                control="input"
                value={values.designate_id}
                type="text"
                label="Designated Id:"
                name="designate_id"
                disabled={true}
              />
              <FormikControl
                control="input"
                value={values.amount}
                type="text"
                label="Amount:"
                name="amount"
                disabled={true}
              />
            </div>
            <div className="px-4 grid grid-cols-1 md:grid-cols-2">
              <Button name="Continue" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default BuyPinForm;

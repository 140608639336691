import React, { useState, useEffect } from "react";
import { Formik, Form,} from "formik";
import * as Yup from "yup";
import Button from "../SelectValue/Button";
import FormikControl from "../SelectValue/FormikControl";
import {
  jambType,
  jambVend,
} from "../../data/local/reducers/miscellaneous.reducer";
import {
  fetchTransactionList,
  fetchUserBalance,
} from "../../data/local/reducers/user.reducer";
import { useDispatch, useSelector } from "react-redux";
import { generateTransactionId } from "../../data/remote/dtos/login.dto";
import Loading from "../SelectValue/Loading";
import { showSuccessToast } from "../../utils/api-utils";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { saveAs } from "file-saver";

function BulkVendForm() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.loginProfile.user_detail[0]);
  const phoneNumber = data.phonenumber;
  const token = useSelector((state) => state.user.loginProfile.token);
  const users = useSelector((state) => state.misc);
  const [isLoading, setIsLoading] = useState(false);
  const [exams, setExams] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { payload } = await dispatch(jambType());
      setExams(payload["result"]);

      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  const examList = exams ? [...exams] : [];

  const initialValues = {
    walletaccount: "",
    exam_type: "",
    uploadfile: null,
  };
  const validationSchema = Yup.object({
    exam_type: Yup.string().required("Required"),
  });

  async function generatePDF(bulkUploadRecords) {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 12;
    const lineHeight = fontSize * 1.2;
    const margin = 50;

    let y = page.getHeight() - margin;

    for (let i = 0; i < bulkUploadRecords.length; i++) {
      const record = bulkUploadRecords[i];
      const lines = record.split("\n"); // Split the record into lines

      for (let j = 0; j < lines.length; j++) {
        page.drawText(lines[j], {
          x: margin,
          y: y - j * lineHeight,
          size: fontSize,
          font,
          lineHeight,
        });
      }

      y -= lines.length * lineHeight;
    }

    const pdfBytes = await pdfDoc.save();

    // Return the PDF file as bytes
    return pdfBytes;
  }

  const processLine = (line, data, token, phoneNumber, exam_type) => {
    const [designate_id, candidate_phonenumber] = line
      .split(",")
      .map((item) => item.trim());
    const lineData = {
      designate_id,
      transaction_id: generateTransactionId(),
      channel: "web",
      user_type: data.user_type,
      token,
      candidate_phonenumber,
      phonenumber: data.phonenumber,
      exam_type: exam_type.split("|")[0],
      exam_details:
        exam_type.split("|")[1] +
        "|" +
        exam_type.split("|")[0] +
        "|" +
        exam_type.split("|")[2],
    };

    return lineData;
  };

  const processRecord = (index, payload) => {
    if (payload && payload.result) {
      const formattedRecord = `${index + 1}. Name: ${
        payload.result.firstname || ""
      } ${payload.result.middlename || ""} ${
        payload.result.lastname || ""
      }\n Pin: ${payload.result.pin || ""}\n Phonenumber: ${
        payload.result.phonenumber || ""
      }\n Designated Id: ${payload.result.designate_id || ""}\n`;
      return formattedRecord;
    } else {
      console.error("Payload or payload.result is undefined:", payload);
      return ""; // Return an empty string or handle the error accordingly
    }
  };

  const onSubmit = async (values, { resetForm }) => {
    const { uploadfile,  exam_type } = values;
    const file = uploadfile;
    const reader = new FileReader();

    const fileReadPromise = new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    try {
      setIsLoading(true);

      reader.readAsText(file);
      const fileContents = await fileReadPromise;
      const records = fileContents.split("\n");
      const bulkUploadRecords = [];

      for (let i = 0; i < records.length; i++) {
        const line = records[i].trim();

        if (line.length > 0) {
          const lineData = processLine(
            line,
            data,
            token,
            phoneNumber,
            exam_type
          );
          const { payload } = await dispatch(jambVend(lineData));
          const formattedRecord = processRecord(i, payload);
          bulkUploadRecords.push(formattedRecord);
        }
      }

      dispatch(
        fetchUserBalance({
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token,
        })
      );
      dispatch(
        fetchTransactionList({
          phonenumber: phoneNumber,
          transaction_id: generateTransactionId(),
          channel: "web",
          token,
        })
      );
      resetForm();
      setIsLoading(false);

      const pdfBytes = await generatePDF(bulkUploadRecords);
      saveAs(
        new Blob([pdfBytes], { type: "application/pdf" }),
        "bulk_upload_records.pdf"
      );

      showSuccessToast("Bulk Operation Completed");
    } catch (error) {
      console.error("Error reading file:", error);
      showSuccessToast("An error occurred during bulk upload.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Loading open={users.loading} />

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, handleChange, touched, setFieldValue }) => (
          <Form>
            <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2">
              <FormikControl
                control="input"
                type="text"
                label="Wallet number:"
                name="walletaccount"
                value={phoneNumber}
                disabled
              />

              <div className="flex items-center">
                <input
                  type="file"
                  id="uploadfile"
                  name="uploadfile"
                  placeholder="hello"
                  onChange={(event) => {
                    setFieldValue("uploadfile", event.currentTarget.files[0]);
                  }}
                  className="border rounded-md border-gray-200 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 file:py-4 file:px-4 file:bg-[#127EC8] file:text-white file:text-xs file:border-0"
                />
              </div>

              <FormikControl
                control="selectExam"
                options={examList}
                label="Select Exam:"
                name="exam_type"
                value={values.exam_list}
              />
            </div>

            <div className="grid grid-cols-1 px-4 md:grid-cols-2">
              <Button name={"Continue"} type="submit" />
            </div>
            {isLoading && <Loading open={isLoading} />}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default BulkVendForm;

import React from 'react'
import { Link } from 'react-router-dom'
import AdvertImage from "../../images/advert_image.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component";

function Advert() {
  return (

    <div className='mx-4 md:mx-16 grid bg-white rounded-lg border my-10 overflow-hidden relative'>
      <div className='h-96'>
        <LazyLoadImage src={AdvertImage} alt="" className='w-full h-96 object-cover'/>
      </div>
      <div className='absolute h-full flex p-4 md:p-16 text-center md:text-left bg-black/80 text-white'>
        <div className='self-center w-full md:w-2/3 '>
          <span className='text-4xl'>🎊</span>
          <p className='font-medium text-lg mt-4 mb-2'>Magodo South West Zone Children's Day Party</p>
          <p className='text-sm font-medium'>Register your children for Magodo South West Zone Children's Day Party on Zippyworld and get 3% commission / Cashback on Airtime and Data, and up to 1% on IKEJADISCO electricity</p>
          <Link to="/">
            <button className="bg-white/10 border text-white px-8 py-6 md:py-4 rounded text-center text-sm mt-6">
              Register
            </button>
          </Link>
        </div>
      </div>
    </div>

  )
}

export default Advert
import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./reducers/user.reducer";
import { MiscellaneousReducer } from "./reducers/miscellaneous.reducer";

const store = configureStore({
  reducer: {
    user: userReducer,
    misc: MiscellaneousReducer
  },
  devTools: false,
});


export default store;
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "jspdf-autotable";
import Loading from "../SelectValue/Loading";
import { getBanks } from "../../data/local/reducers/miscellaneous.reducer";
import { List } from "@mui/material";

const BulkTransferList = () => {
  const [bankData, setBankData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const loginProfile = useSelector((state) => state.user.loginProfile);
  const userBalanceProfile = useSelector(
    (state) => state.user.UserBalanceProfile
  );
  const phoneNumber = loginProfile.user_detail[0].phonenumber;

  const users = useSelector((state) => state.misc);
  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { payload } = await dispatch(getBanks());
      setBankData(payload || null);
    };
    fetchData();
  }, [dispatch, phoneNumber]);

  const listTransaction = (bankData || []).filter((referral) =>
    referral.bankName.toLowerCase().includes(searchText.toLowerCase())
  );

  const limitedTransactions = listTransaction.slice(startIndex, endIndex);

  return (
    <div className="">
      <Loading open={users.loading} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 border-b p-4 items-center">
        <div className="relative mt-1 md:col-span-2">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search Bank Name..."
            className="block p-2 pl-10 text-sm text-black border border-gray-300 rounded-lg w-full md:w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>

      <div className="rounded-t-md max-w-full overflow-x-scroll m-4 print-table">
        <table className="w-full text-sm text-left">
          <thead className="text-[#127EC8] bg-[#127EC830]">
            <tr>
              <th className="py-6" style={{ maxWidth: "80px" }}>
                S/N
              </th>
              <th className="px-6" style={{ maxWidth: "100px" }}>
                Bank Name
              </th>
              <th className="px-6" style={{ maxWidth: "100px" }}>
                Bank Code
              </th>
            </tr>
          </thead>
          <tbody className="">
            {limitedTransactions.map((object, index) => (
              <tr
                className="bg-white border-b text-black text-[13px]"
                key={index}
              >
                <td className="px-6 py-4 font-medium">
                  {startIndex + index + 1}
                </td>
                <td className="px-6 py-4 font-medium whitespace-nowrap">
                  {object.bankName}
                </td>
                <td className="px-6 py-4 font-medium whitespace-nowrap">
                  {object.bankCode}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between p-4 text-xs font-semibold gap-12 ">
        <div className="opacity-60">
          {listTransaction.length > 0
            ? `Showing transactions ${startIndex + 1} - ${Math.min(
                endIndex,
                listTransaction.length
              )}`
            : "No transactions found"}
        </div>
        <div className="flex  items-center overflow-x-auto">
          <span>Page:</span>
          <span className="flex gap-2 ml-2">
            {Array.from({ length: Math.ceil(listTransaction.length / 10) }).map(
              (_, index) => (
                <span
                  key={index}
                  className={`${
                    currentPage === index + 1
                      ? "bg-[#127ec8] text-white rounded-full"
                      : ""
                  } w-6 h-6 grid place-content-center cursor-pointer`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </span>
              )
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BulkTransferList;

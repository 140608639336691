import React from "react";
import Back from "../../Components/Back";
import Referrals from "../../Components/referrals";
import StoreAd from "../../Components/storeAdvert";
import { Chart } from "../../Components/Dashboard/chart";
import DownloadTemplate from "../../Components/DownloadTemplate";

function Referral() {


  return (
    <div>
      <div className="flex items-center text-sm font-bold gap-4 border-b pb-4">
        <Back />
        <span>Reports</span>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5">
        <div className="lg:col-span-2">
          <div className="grid grid-cols-1 gap-5">
            <div className="rounded-lg border text-sm">
            <DownloadTemplate/>
              <div className="py-4 px-4 grid grid-cols-1">
                <Referrals />
              </div>
              <div className="py-4 px-4 grid grid-cols-1">
                <Chart />
              </div>
            </div>
          </div>
        </div>
        <div>
          <StoreAd />
        </div>
      </div>
    </div>
  );
}

export default Referral;

import React from "react";
import RecentTransactions from "../../Components/recentTransactions";
import StoreAd from "../../Components/storeAdvert";
import Back from "../../Components/Back";
import { Link } from "react-router-dom";

function JambOption() {
  const jambOptions = [
    {
      id: 1,
      name: "Vend E-Pin",
      link: "/vendPin",
    },
    {
      id: 2,
      name: "Retrieve Pin",
      link: "/retrievePin",
    },
    {
      id: 3,
      name: "Transaction History",
      link: "/jambHistory",
    },
    {
      id: 4,
      name: "Bulk Vend E-Pin",
      link: "/bulkVend",
    },
    // {
    //   id: 4,
    //   name: "Swap Pin",
    //   link: "/tokenHistory",
    // },
    // {
    //   id: 5,
    //   name: "Unit Stand",
    //   link: "",
    // },

    // {
    //   id: 7,
    //   name: "Sales agent",
    //   link: "/fibreOptions",
    // },
  ];

  return (
    <div>
      <div className="flex items-center text-sm font-bold gap-4 border-b pb-4">
        <Back />
        Jamb
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5">
        <div className="lg:col-span-2">
          {jambOptions.map((jamb) => (
            <div className="w-full mb-5 text-sm font-semibold" key={jamb.id}>
              <Link
                to={{
                  pathname: `${jamb.link}`,
                }}
                className="flex justify-between p-4 hover:bg-[#127EC810] hover:text-[#127EC8] hover:rounded-md hover:px-6 transition duration-300 "
              >
                <div>{jamb.name}</div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"
                      fill="rgba(0,0,0,1)"
                    ></path>
                  </svg>
                </div>
              </Link>
            </div>
          ))}

          <StoreAd />
        </div>
        <div>
          <RecentTransactions />
        </div>
      </div>
    </div>
  );
}

export default JambOption;

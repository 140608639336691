import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function NavCard({ serviceImg, serviceName }) {
  return (
    <div className="rounded-md overflow-hidden">
      <div className="h-36 overflow-hidden">
        <LazyLoadImage
          src={serviceImg}
          alt=""
          className="h-full w-full object-cover"
        />
      </div>
      <div className="flex justify-between items-center p-4 border-x border-b">
        <div className="text-sm">{serviceName}</div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="w-5 h-5"
        >
          <path
            fillRule="evenodd"
            d="M5 10a.75.75 0 01.75-.75h6.638L10.23 7.29a.75.75 0 111.04-1.08l3.5 3.25a.75.75 0 010 1.08l-3.5 3.25a.75.75 0 11-1.04-1.08l2.158-1.96H5.75A.75.75 0 015 10z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
}

export default NavCard;

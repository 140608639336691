import GetStarted from "../../Components/Home/getStarted"
import Footer from "../../Components/Home/Footer"
import Nav from "../../Components/Home/Nav"
import TermsAndConditions from "../../Components/Home/termsCondition"

function TermsCondition() {
   
  return (
    <div className="home-bg bg-slate-600">
      <Nav/>
        <TermsAndConditions />
      <GetStarted />
      <Footer />
    </div>
  )
}

export default TermsCondition;

import React from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import TextError from "./TextError";

function SelectBillerCategory(props) {
  const { label, name, options, ...rest } = props;
  const { isAmountFixed } = rest;
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className="mb-3">
      <label className="text-pri-text block" htmlFor={name}>
        {label}
      </label>
      <Field
        as="select"
        className="mb-1 w-full border rounded-md border-gray-200 p-3 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"
        id={name}
        name={name}
        value={values[name]} 
        onChange={(e) => {
          setFieldValue(name, e.target.value); 
          if (rest.onChange) {
            rest.onChange(e); 
          }
        }}
        {...rest}
      >
        <option value="">Select product</option>
        {options.map((option, index) => (
          <option key={index} value={option.billPaymentProductId}>
            {option.billPaymentProductName} - {option.amount} {option.currency}
          </option>
        ))}
      </Field>
      <ErrorMessage component={TextError} name={name} />

    </div>
  );
}

export default SelectBillerCategory;

import Axios from "axios";
import { EXAM_HEADER, HEADER, JAVA_HEADER, UPLOAD_HEADER } from "../../../utils/constant";

export const apiClient = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: HEADER,
});

export const invoiceApiClient = Axios.create({
  baseURL: process.env.REACT_APP_INVOICE_BASE_URL,
  headers: HEADER,
});

export const apiLiveClient = Axios.create({
  baseURL: process.env.REACT_APP_VERIFICATION_API_URL,
  headers: HEADER,
});

export const uploadInvoiceApiClient = Axios.create({
  baseURL: process.env.REACT_APP_INVOICE_BASE_URL,
  headers: UPLOAD_HEADER,
});

export const javaClient = Axios.create({
  baseURL: process.env.REACT_APP_JAVA_API_URL,
  headers: JAVA_HEADER,
  timeout: 500000,
});


//REACT_APP_NODE_API_URL
export const apiNodeClient = Axios.create({
  baseURL: process.env.REACT_APP_NODE_API_URL,
  headers: EXAM_HEADER,
  timeout: 500000,
});

//REACT_APP_WAEC_API_URL
export const apiWAECClient = Axios.create({
  baseURL: process.env.REACT_APP_WAEC_API_URL,
  headers: EXAM_HEADER,
  timeout: 500000,
});
import illustration from '../images/error.svg';
import { Link } from "react-router-dom";

function Error404() {
  return (
    <div className="h-screen grid place-items-center">
      <div className='grid gap-6'>
        <div className='flex justify-center'>
        <img src={illustration} alt="" />
        </div>
        <div className='grid gap-2'>
          <p className='text-lg text-[#127ec8] text-center'>Sorry, this page isn’t available</p>
          <p className='text-sm text-center'>It looks like the page you’re looking for doesn’t exist. <br /> Retrace your steps from the homepage</p>
        </div>
        <Link to="/login">
            <div className="bg-[#127ec8] text-white px-4 py-6 md:py-4  rounded text-center text-sm">Back to homepage</div>
        </Link>
      </div>
    </div>
  );
}

export default Error404;

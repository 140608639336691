import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

function Select (props) {
  const { label, name, options, ...rest } = props
  return (
    <div className=' mb-3'>
      <label className=' text-pri-text block' htmlFor={name}>{label}</label>
      <Field as='select' className='mb-1 w-full border rounded-md border-gray-200 p-3 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500' id={name} name={name} {...rest}>
        {options.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.key}
            </option>
          )
        })}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Select

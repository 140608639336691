import React from 'react'
import RecentTransactions from '../../Components/recentTransactions'
import StoreAd from '../../Components/storeAdvert'
import Back from '../../Components/Back'
import { useLocation } from 'react-router-dom';
import GenerateGatePassForm from '../../Components/PrivateEstates/generateGatePass'
import DownloadTemplate from '../../Components/DownloadTemplate';

function GenerateGatePass() {
  const location = useLocation();
  const dataReceived = new URLSearchParams(location.search).get('data');


  return (
    <div>

      <div className='flex items-center text-sm font-bold gap-4 border-b pb-4'>
        <Back />
        <span>Generate Gate Pass</span>
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5'>
         <div className='lg:col-span-2'>
           <div className='grid grid-cols-1 gap-5'>
           
           <div className='rounded-lg border text-sm'>
            <DownloadTemplate/>
            <div className='py-4 grid grid-cols-1'>
              <GenerateGatePassForm dataReceived = {dataReceived}/>
            </div>
           </div>
             
           </div> 
         </div>
         <div className='grid gap-5'>
          <StoreAd />
          <RecentTransactions />
         </div>
      </div>
    </div>
  )
}

export default GenerateGatePass
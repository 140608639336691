import { useEffect, useState } from "react";
import { showInfoToast, showSuccessToast } from "../utils/api-utils";

const LogoutTimer = () => {
  const [timer, setTimer] = useState(null);
  const [logoutWarning, setLogoutWarning] = useState(false);

  const logouts = () => {
    sessionStorage.removeItem("users");
    sessionStorage.removeItem("loginProfile");
    sessionStorage.removeItem("verifyBvnProfile");
    sessionStorage.removeItem("fetchSecurityQuestionProfile");
    sessionStorage.removeItem("UserBalanceProfile");
    sessionStorage.removeItem("fetchTransactionProfile");
    sessionStorage.removeItem("userCommissionProfile");
    sessionStorage.removeItem("qrBalanceProfile");
    sessionStorage.removeItem("fetchTransactionFlowProfile");
    sessionStorage.removeItem("estateMeterValidationProfile");
    sessionStorage.removeItem("fetchJambTransactionProfile");
    window.location.replace("/login");
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLogoutWarning(true);
    }, 1200000);

    setTimer(timeoutId);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  useEffect(() => {
    if (logoutWarning) {
      showInfoToast("You will be logged out in 1 minute, Kindly Re-Login");

      const logoutTimeoutId = setTimeout(() => {
        sessionStorage.removeItem("users");
        sessionStorage.removeItem("loginProfile");
        sessionStorage.removeItem("verifyBvnProfile");
        sessionStorage.removeItem("fetchSecurityQuestionProfile");
        sessionStorage.removeItem("UserBalanceProfile");
        sessionStorage.removeItem("fetchTransactionProfile");
        sessionStorage.removeItem("userCommissionProfile");
        sessionStorage.removeItem("qrBalanceProfile");
        sessionStorage.removeItem("fetchTransactionFlowProfile");
        sessionStorage.removeItem("estateMeterValidationProfile");
        sessionStorage.removeItem("fetchJambTransactionProfile");
        window.location.replace("/login");
      }, 60000);

      setTimer(logoutTimeoutId);
      return () => {
        if (logoutTimeoutId) {
          clearTimeout(logoutTimeoutId);
        }
      };
    }
  }, [logoutWarning]);

  return null;
};

export default LogoutTimer;

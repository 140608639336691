import React from 'react'

function SavedBeneficiaryButton({onClick}) {
  return (
    <>
    <button
    onClick={onClick}
    type='button'
    >
      <p className='text-[#127EC8] text-xs '>Saved beneficiaries</p>
    </button>
    </>
  )
}

export default SavedBeneficiaryButton
import React from 'react'
import { useNavigate } from 'react-router-dom';
import Backk from '../images/backz.png'

function Back() {
    const navigate = useNavigate();
  return (
    <div className='cursor-pointer' onClick={() => navigate(-1)}>
        <img src={Backk} alt='Back'/>
    </div>
  )
}

export default Back